import React, { useState } from 'react';
import { Paper, Grid } from '@mui/material';
import cross from '../../../../../assets/images/common/cross.png';
import purpleEdit from '../../../../../assets/images/common/purpleEdit.svg';
import '../styles.scss';
import typography from '../../../../../theme/typography';
import CustomTypography from '../../../../../components/CustomTypography';
import moment from 'moment';

const ParameterDailyCard = ({ title, value, parameter, onEdit, onDelete, valueType, currency, notes }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };
    
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const getValue = (val) => {
    switch(valueType){
      case 1:
        return (
          moment(val).format('DD/MM/YYYY')
        );
      case 2: 
      return (
          currency === "usd" ? `$${val}` : `€${val}`
      );
      case 4:
        return `${val}%`  ;
      default:
        return val;  
    }
  }

  return (
    <Grid
      item
      xs={6}
      sm={12}
      className='parameter-card-container'
      key={parameter.id}
      spacing={2}
      pb={2}
      sx={{ position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Paper
        className="parameter-daily-card-wrapper"
        elevation={3}
        sx={{ padding: 2 }}
      >
        <div>
          <div className="daily-parameter-inner-wrapper">
            <CustomTypography
              text={title}
              style={{ ...typography.semibold, marginBottom: '5px' }}
            />
            <CustomTypography text={notes} style={typography.bodyB2} />
          </div>
          <CustomTypography text="Hours Per Week" style={typography.caption} />
          <div className="daily-parameter-container">
            <div className="daily-parameter-card" style={{ padding: 0 }}>
              <div className="table-row">
                {daysOfWeek.map((day) => (
                  <CustomTypography
                    key={day}
                    text={day}
                    style={typography.caption}
                  />
                ))}
              </div>
              <div className="table-row">
                {daysOfWeek.map((day, index) => (
                  <CustomTypography
                    key={day}
                    text={typeof value[index] === 'string' ? getValue(value[index]) : ''}
                    style={typography.semibold}
                  />
                ))}
              </div>
            </div>
            <div className="card-actions">
              {hovered && (
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  onClick={() => onEdit(parameter)}
                >
                  <img src={purpleEdit} alt="edit" />
                </button>
              )}
            </div>
          </div>
        </div>
      </Paper>
      {hovered && (
        <div style={{ position: 'absolute', top: 8, right: -10, cursor: 'pointer' }}>
          <img
            src={cross}
            alt="cross"
            onClick={() => onDelete(parameter?.id)}
          />
        </div>
      )}
    </Grid>
  );
};

export default ParameterDailyCard;
