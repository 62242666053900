export const timeStandardOptions = [
    { value: "+0", label: 'UTC' },
    { value: 2, label: 'Local time', additionalFieldType: 'select', options: [
        { value: '+1', label: 'British Summer Time (BST)' },
        { value: '+05:30', label: 'India Standard Time' },
    ]},
    { value: 3, label: 'Other', additionalFieldType: 'text' },
];
export const timeFormatOptions = [
    { value: 24, label: '24 hour' },
    { value: 12, label: '12 hour (AM/PM)' },
];
export const weekModelTabs = [
{ value: "DD/MM/YYYY", label: 'DD/MM/YYYY'},
{ value: 'MM/DD/YYYY', label: 'MM/DD/YYYY'},
{ value: 'DD/MMM/YY', label: 'DD/MMM/YY'},
];