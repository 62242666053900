import React from 'react';
import TextInput from '../../../../components/TextInput';

const CopyRole = ({ copyRole, setCopyRole, errors }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCopyRole({
      ...copyRole,
      [name]: value,
    });
  };

  return (
    <div className="pd-24 d-flex fd-column" style={{ gap: '24px' }}>
      <TextInput
        label="Role"
        name="newRole"
        value={copyRole.newRole}
        onInputChange={handleChange}
        helperText={errors.newRole}
      />
    </div>
  );
};

export default CopyRole;
