import React from "react";
import PropTypes from "prop-types";
import { Toolbar } from "@mui/material";
import ThemeButton from "../ThemeButton";
import typography from "../../theme/typography";
import './styles.scss'
import CustomTypography from "../CustomTypography";
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

const EnhancedToolbar = (props) => {
    const { selectedItems, tableOf, onUnSelect, onDelete, rows } = props;

    const handleDelete = (status) => {
        onDelete(selectedItems, status)
    }

    const activeRows = rows.filter((item) => item.status === 'active');
    const archivedRows = rows.filter((item) => item.status === 'archive');
    const draftRows = rows.filter((item) => item.status === 'draft');

    return (
        <div className="table-toolbar">
        <Toolbar>
            <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                <CustomTypography style={typography.bodyB2} text={`${selectedItems.length} ${selectedItems.length === 1 ? tableOf[0] : tableOf[1]} selected`} />
                <ThemeButton
                    variant="text"
                    text={`${selectedItems.length === 1 ? 'Unselect' : "Unselect All"}`}
                    onClick={onUnSelect}
                />
            </div>
            <div className="d-flex" style={{gap: '6px'}}>
            {activeRows && activeRows.length > 0 && <ThemeButton
                variant="contained"
                text="Archive"
                onClick={() => handleDelete('archive')}
                style={{ height: '32px' }}
                startIcon={<ArchiveOutlinedIcon />}
            />}
            {archivedRows && archivedRows.length > 0 && <ThemeButton
                variant="contained"
                text="Activate"
                onClick={() => handleDelete('active')}
                startIcon={<PlayArrowOutlinedIcon />} 
            />}
            {((activeRows && activeRows.length === 0) || (archivedRows && archivedRows.length > 0) || (draftRows && draftRows.length > 0)) && <ThemeButton
                variant="contained"
                text="Delete"
                onClick={() => handleDelete('delete')}
                color='danger'
                style={{ height: '32px' }}
            />}
            </div>
        </Toolbar>
        </div>
    )
};

EnhancedToolbar.propTypes = {
    selectedItems: PropTypes.array,
    tableOf: PropTypes.array,
    onUnSelect: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

EnhancedToolbar.defaultProps = {
    selectedItems: [],
    tableOf: []
};

export default EnhancedToolbar;