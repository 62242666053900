import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import ThemeButton from "./ThemeButton";
import typography from "../theme/typography";
import "./styles.scss";
import CustomTypography from "./CustomTypography";

const ActionModal = ({
  component,
  open,
  close,
  actionButtons,
  title,
  width,
  subtitle,
  className
}) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      <Box className={`modal-container ${className}`} style={{ width: width }}>
        <div className="pd-md flex-container sticky-header">
          <CustomTypography style={typography.headerh5} text={title} />
          <CustomTypography style={typography.overline} text={subtitle} />
        </div>
        <div className="modal-body">{component}</div>
        <div className="flex-container pd-md sticky-footer">
          {actionButtons.map((btn) => (
            <ThemeButton
              key=""
              text={btn.title}
              variant={btn.variant}
              onClick={btn.onClick}
              type={btn.type}
              isDisabled={btn.isDisabled}
            />
          ))}
        </div>
      </Box>
    </Modal>
  );
};

ActionModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  component: PropTypes.element.isRequired,
  actionButtons: PropTypes.array,
  title: PropTypes.string,
  width: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

ActionModal.defaultProps = {
  open: false,
  close: true,
  actionButtons: [],
  title: "",
};

export default ActionModal;
