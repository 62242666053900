import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo/re-budget-large.svg';
import user from '../../assets/images/login/user.svg';
import lock from '../../assets/images/login/lock.svg';
import eye from '../../assets/images/login/eye.svg';
import eyeOff from '../../assets/images/login/eyeOff.svg';
import { useNavigate } from 'react-router-dom';
import typography from '../../theme/typography';
import TextInput from '../../components/TextInput';
import ThemeButton from '../../components/ThemeButton';
import './styles.scss';
import CustomTypography from '../../components/CustomTypography';
import { required, validateField } from '../../utils/Validator';
import { useNotification } from '../../utils/NotificationProvider';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/slice/login';
import { isValidEmail } from '../../utils/helper';
import OtpVerification from './otpVerification';

const validationRules = {
  email: [required('Email is required'), isValidEmail('Invalid email address')],
  password: [required('Password is required')],
};

const Login = () => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false)
  const showNotification = useNotification();
  const [errors, setErrors] = useState({});
  const [visibility, setVisibility] = useState(true);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token){
      navigate('/dashboard')
    }
  }, []);  

  const handleChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };

  const handleSubmit = () => {
    if (!validateField(values, validationRules, setErrors)) return;
    setLoading(true)
    dispatch(
      loginUser({
        url: '/users/login',
        method: 'POST',
        data: values,
        navigate,
        success: (res) => {
          if (res) {
            localStorage.setItem('userEmail', values.email);
            if (res.data && res.data.token) {
              const token = res?.data?.token;
              localStorage.setItem('token', token);
              const firstName = res?.data?.firstName;
              localStorage.setItem('firstName', firstName);
              const lastName = res?.data?.lastName;
              localStorage.setItem('lastName', lastName);
              const role = res?.data?.role;
              localStorage.setItem('role', role);
              navigate('/dashboard');
            } else if (
              res.success &&
              res.message === 'OTP sent successfully.'
            ) {
              if (res.data) {
                localStorage.setItem('otpToken', res.data);
              }
              showNotification('success', res.message);
              setShowOtpScreen(true);
            }
          } else {
            const message = res.message || 'Something went wrong';
            showNotification('error', message);
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  return (
    <div className="login-container">
      {showOtpScreen ? <OtpVerification /> : <div className="login-wrapper">
        <img src={logo} alt="logo" className='logo-image'></img>
        <div className="login-heading">
          <CustomTypography style={typography.headerh3} text="Login" />
          <CustomTypography
            className="login-subtitle"
            style={typography.subtitleS1}
            text="Enter your details to login to your account"
          />
        </div>
        <div className='login-form'>
          <TextInput
            label="Email"
            placeholder="Email"
            name="email"
            type="text"
            startAdornment={<img src={user} alt="" />}
            onChange={handleChange('email')}
            helperText={errors.email}
            width="100%"
          />

          <TextInput
            label="Password"
            placeholder="Password"
            name="password"
            type={!visibility ? 'text' : 'password'}
            onChange={handleChange('password')}
            helperText={errors.password}
            startAdornment={<img src={lock} alt="" />}
            width="100%"
            endAdornment={
              <img
                src={visibility ? eyeOff : eye}
                alt="toggle visibility"
                style={{ cursor: 'pointer' }}
                onClick={toggleVisibility}
              />
            }
          />

          <ThemeButton
            type="submit"
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={handleSubmit}
            isFullWidth={true}
            text="Sign In"
            width="100%"
            loading={loading}
          />
          <div className="forgot-password-btn">
            <ThemeButton
              type="submit"
              variant="text"
              style={{ textTransform: 'none' }}
              onClick={handleForgotPassword}
              isFullWidth={true}
              text={
                <CustomTypography
                  text="Forgot Password?"
                  style={typography.overline}
                  className="forgot-password-btn"
                />
              }
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default Login;
