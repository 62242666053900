import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import SelectDropdown from '../../../../components/SelectDropdown';
import CustomTabs from '../../../../components/CustomTabs';
import TextInput from '../../../../components/TextInput';
import { Grid } from '@mui/material';
import {
  currencyOpt,
  budgetTypeOpt,
  weekDays,
  weekModelTabs,
} from './constant';
import typography from '../../../../theme/typography';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import Groups from './Group';
import moment from 'moment';
import { get } from 'lodash';
import { ValueTypes } from './Group/constant';
import { fetchModel } from '../../../../redux/slice/model';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../utils/loading';
import { globalSettingRules } from '../modelRules';
import ThemeButton from '../../../../components/ThemeButton';
import eye from '../../../../assets/images/common/purpleEye.svg';
import eyeOff from '../../../../assets/images/common/purpleEyeOff.svg';
import WeeklyDetailsTable from './weeklyDetailsTable';

const GlobalSettings = ({ formData, setFormData, errors, editMode, modelId, completedSteps, loading, validationRules, setValidationRules }) => {
  const [globalSettingsData, setGlobalSettingsData] = useState({});
  const [prevData, setPrevData] =  useState({});
  const [isWeeklyDetail, setIsWeeklyDetail] =  useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStepCompleted = get(completedSteps, 'globalSettings', false);
  const getModel = () => {
    setIsLoading(true);
    dispatch(
      fetchModel({
        url: `/models/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          const data = get(res, 'data[0].model', {});
          setGlobalSettingsData(data);
          setPrevData(JSON.parse(JSON.stringify(data)));
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('err of single model', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if ((editMode || isStepCompleted) && modelId) {
      getModel();
    }
  }, [isStepCompleted, modelId]);

  const initialData = {
    fyName: (editMode || isStepCompleted) ? get(globalSettingsData, 'name') : get(formData, 'globalSettings.fyName', ''),
    budgetType: (editMode || isStepCompleted) ? (get(globalSettingsData, 'budgetType') === 'daily' ? '2' : '1') : get(formData, 'globalSettings.budgetType', '1'),
    modelWeeks: (editMode || isStepCompleted) ? weekModelTabs.find((item) => item.title === get(globalSettingsData, 'numberOfSplit'))?.value : get(formData, 'globalSettings.modelWeeks', '1'),
    weekStartDay: (editMode || isStepCompleted) ? weekDays.find((item) => item.label.toLowerCase() === get(globalSettingsData, 'weekStartDay'))?.value : get(formData, 'globalSettings.weekStartDay', '1'),
    currency: (editMode || isStepCompleted) ? currencyOpt.find((item) => item.label === get(globalSettingsData, 'currency'))?.value : get(formData, 'globalSettings.currency', currencyOpt[0].value),
    budgetEndDate: (editMode || isStepCompleted) ? moment(get(globalSettingsData, 'budgetEndDate')).format('DD/MM/YYYY') : get(formData, 'globalSettings.budgetEndDate', null),
    budgetStartDate: (editMode || isStepCompleted) ? get(globalSettingsData, 'budgetStartDate') : get(formData, 'globalSettings.budgetStartDate', null),
    parameters: (editMode || isStepCompleted) ? get(globalSettingsData, 'parameterGroups', []).map((grp) => ({
      groupName: grp.name,
      parameters: get(grp, 'parameters', []).map((param) => ({
        parameterName: param.name,
        notes: param.note,
        type: ValueTypes.find((item) => item.type === param.type)?.value,
        value: param.values,
        id: param._id 
      })),
      id: grp._id 
    })) : get(formData, 'globalSettings.parameters', []),
  };

  const getBudgetEndDate = () => {
    const budgetStart = formData?.globalSettings?.budgetStartDate ? new Date(formData?.globalSettings?.budgetStartDate) : null;
    const noOfWeeks = formData?.globalSettings?.modelWeeks === '2' ? 53 : 52;
    const budgetEnd = budgetStart ? budgetStart.setDate(budgetStart.getDate() + noOfWeeks * 7) : null;
    const finalDate = budgetEnd ? moment(budgetEnd).format('DD/MM/YYYY') : null
    return finalDate;
  }

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      globalSettings: globalSettingRules
    })
  }, [])

  useEffect(() => {
      setFormData({
        ...formData,
        globalSettings: {
          ...formData.globalSettings,
          budgetEndDate: getBudgetEndDate()
        }
      })
  }, [formData?.globalSettings?.modelWeeks, formData?.globalSettings?.budgetStartDate])

  useEffect(() => {
    setFormData({
      ...formData,
      globalSettings: {
        ...initialData,
        prevData,
      },
    });
  }, [globalSettingsData, editMode, isStepCompleted, prevData])


  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      ...formData,
      globalSettings: {
        ...prevState.globalSettings,
        [name]: value,
      },
    }));
  };

  const handleWeeklyDetails = () => {
    setIsWeeklyDetail(!isWeeklyDetail);
  }

  return (
    <>
    {(loading || isLoading) ? <Loading /> :
    <>
    <div className="step-header">
        <CustomTypography variant="headerh3" text="Global Settings" />
    </div>
    <div className="pd-md">
      <Grid container columnSpacing={5} rowSpacing={2} py={2}>
        <Grid item>
          <TextInput
            label="Model name"
            placeholder="e.g. FY 23"
            className="text-input-height"
            name="fyName"
            onInputChange={(e) => handleChange(e.target.name, e.target.value)}
            value={formData?.globalSettings?.fyName}
            helperText={errors.fyName}
          />
        </Grid>
        <Grid item>
          <CustomTabs
            label="Budget type"
            tabs={budgetTypeOpt}
            value={formData?.globalSettings?.budgetType ? formData?.globalSettings?.budgetType : '1'}
            onChange={(name, value) => handleChange(name, value)}
            name="budgetType"
            disabled={isStepCompleted}
          />
        </Grid>
        <Grid item>
          <CustomTypography text="Currency" style={typography.subtitle2} />
          <SelectDropdown
            label="Currency"
            classNameForFormControl="custom-min-width"
            items={currencyOpt}
            value={formData?.globalSettings?.currency ? formData?.globalSettings?.currency : currencyOpt[0].value}
            handleChange={(e) => handleChange(e.target.name, e.target.value)}
            name="currency"
          />
        </Grid>
        <Grid item>
          <CustomTabs
            label="Model weeks"
            tabs={weekModelTabs}
            onChange={(name, value) => handleChange(name, value)}
            value={formData?.globalSettings?.modelWeeks ? formData?.globalSettings?.modelWeeks : '1'}
            name="modelWeeks"
            disabled={isStepCompleted}
          />
        </Grid>
      </Grid>
      <div className="divider" />
      <Grid container columnSpacing={5} rowSpacing={3} py={3}>
        <Grid item>
          <CustomTabs
            label="Week start day"
            tabs={weekDays}
            // tabs={formData?.globalSettings?.budgetType === '1' ? weekStartDayTabs : [weekStartDayTabs[0], ...weekDays, weekStartDayTabs[1]]}
            onChange={(name, value) => handleChange(name, value)}
            value={formData?.globalSettings?.weekStartDay ? formData?.globalSettings?.weekStartDay : '1'}
            name="weekStartDay"
          />
        </Grid>
        <Grid item>
          <CustomDatePicker
            title="Budget start date"
            width="170px"
            value={formData.globalSettings?.budgetStartDate ? formData.globalSettings.budgetStartDate : null}
            onChange={(date) => handleChange("budgetStartDate", date)}
            helperText={errors.budgetStartDate}
          />
        </Grid>
        <Grid item>
          <CustomTypography style={{ ...typography.subtitle2, marginBottom: '11px' }} text="Budget end date" />
          <CustomTypography style={{...typography.bodyB2, }} text={formData.globalSettings?.budgetEndDate ? formData.globalSettings.budgetEndDate : null} />
        </Grid>
        {
          (formData?.globalSettings?.budgetType === "1" && formData.globalSettings?.budgetStartDate) &&
          <Grid item>
            <ThemeButton 
              text={isWeeklyDetail ? 'Hide details' : 'See details'}
              startIcon={isWeeklyDetail ? <img src={eyeOff}/> : <img src={eye}/>}
              onClick={handleWeeklyDetails}
              variant="text"
              style={{marginTop: '25px'}}
            />
          </Grid>
        }
        {
          (formData?.globalSettings?.budgetType === "1" && isWeeklyDetail) &&
            <Grid item md={12}>
              <WeeklyDetailsTable
                startDate={formData.globalSettings?.budgetStartDate}
                endDate={formData.globalSettings?.budgetEndDate}
                startDay={formData.globalSettings?.weekStartDay}
                noOfWeeks = {formData?.globalSettings?.modelWeeks}
              />
            </Grid>
        }
      </Grid>
      <div className="divider" />
      <Grid item>
        <Groups
          budgetType={formData.globalSettings?.budgetType}
          setFormData={setFormData}
          formData={formData}
          globalSettingsData={globalSettingsData}
          editMode={editMode}
        />
      </Grid>
    </div>
    </>}
    </>
  );
};

export default GlobalSettings;
