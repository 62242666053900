import React, { useEffect } from 'react';
import TextInput from '../../../../components/TextInput';
import SelectDropdown from '../../../../components/SelectDropdown';
import CustomTypography from '../../../../components/CustomTypography';
import typography from '../../../../theme/typography';

const AddCalendarItem = ({ newItem, setNewItem, errors, booleanType }) => {
  useEffect(() => {
    setNewItem((prev) => ({
      ...prev,
      value: '',
    }));
  }, [newItem.type]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const inputValueType = [
    { label: 'Number', value: 'number', type: 'number' },
    { label: 'Percent', value: 'percent', type: 'number' },
    { label: 'Boolean', value: 'boolean', type: 'text' },
    { label: 'Free', value: 'free', type: 'text' },
  ];

  const renderInput = () => {
    switch (newItem.type) {
      case 'boolean':
        return (
          <div className="w-50">
            <CustomTypography text="Value" style={typography.subtitle2} />
            <SelectDropdown
              name="value"
              value={newItem.value}
              handleChange={(e) => handleInputChange(e)}
              minWidth="100%"
              items={booleanType}
              helperText={errors.value}
            />
          </div>
        );
      default:
        return (
          <TextInput
            type={newItem.type === 'free' ? 'text' : 'number'}
            label="Value"
            name="value"
            value={newItem.value}
            onChange={(e) => handleInputChange(e)}
            width="50%"
            helperText={errors.value}
          />
        );
    }
  };

  return (
    <div className="pd-24">
      <TextInput
        label="Name"
        name="name"
        value={newItem.name}
        onChange={(e) => handleInputChange(e)}
        mb="15px"
        helperText={errors.name}
      />
      <div className="d-flex g-8">
        <div className="w-50">
          <CustomTypography text="Value Type" style={typography.subtitle2} />
          <SelectDropdown
            label="Type"
            name="type"
            value={newItem.type}
            handleChange={(e) => handleInputChange(e)}
            items={inputValueType}
            minWidth="100%"
            helperText={errors.type}
          />
        </div>
        {renderInput()}
      </div>
    </div>
  );
};

export default AddCalendarItem;
