import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import whiteAdd from "../../assets/images/common/whiteAdd.svg"
import DataTable from '../../components/DataGrid/DataTable';
import CustomDialogBox from '../../components/CustomDialogBox';
import { useDispatch } from 'react-redux'; 
import { useNotification } from '../../utils/NotificationProvider';
import { changeCompanyStatus, fetchCompanies, filterCompanies } from '../../redux/slice/company';
import moment from 'moment';
import NoDataFoundMessage from '../../components/NoDataFoundMessage';
import CustomTypography from '../../components/CustomTypography';
import ThemeButton from '../../components/ThemeButton';
import theme from '../../theme';
import { appConfig } from '../../config/appConfig';
import { fetchModelList } from '../../redux/slice/model';
import { capitalize } from 'lodash';

const Company = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [isAlert, setAlert] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [statusAction, setStatusAction] = useState("")
  const [isDataFilter, setIsDataFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalCompanies, setTotalUsers] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [initialFilters, setInitialFilters] = useState({
    companyFilter: [],
    modelFilter: [],
    subscriptionEndDateFilter: "",
    statusFilter: ['active', 'archive'],
    searchText: "",
    page: 1,
    limit: 10
  })

const updateFilteredRows = (data) => {
  setIsDataFilter(true)
  const { Model, searchQuery, Company, subscriptionEndDate, page, limit, statusFilter } = data;
  const companyFilter = Company ? Object.keys(Company).filter(key => Company[key]) : [];
  const modelFilter = Model ? Object.keys(Model).filter(key => Model[key]) : [];
  
  const requestedPayload = {
      companyFilter,
      modelFilter,
      statusFilter: statusFilter ? statusFilter : initialFilters.statusFilter,
      searchText: searchQuery ?? "",
      subscriptionEndDateFilter: subscriptionEndDate ? moment(new Date(subscriptionEndDate)).format('yyyy-MM-DD') : '',
      page: page || initialFilters.page,
      limit: limit || initialFilters.limit,
  };
  setInitialFilters(prevFilters => ({
      ...prevFilters,
      ...requestedPayload,
  }));
};

const searchFilterOpt = [
  {name: 'Company', options: companyList, type: 'checkbox'},
  {name: 'Model', options: modelList, type: 'checkbox'},
  {name: 'Subscription end', type: 'date-picker', keyName: 'subscriptionEndDate'},
]

  const getCompanies = () => {
    setLoading(true)
    dispatch(filterCompanies({
      url: '/companies/filter',
      method: 'POST',
      navigate,
      data : {
        companyFilter: initialFilters.companyFilter,
        modelFilter: initialFilters.modelFilter,
        subscriptionEndDateFilter: initialFilters.subscriptionEndDateFilter,
        searchFilter: {
          searchField: [
            "name",
            "uniqueName",
        ],
        searchText: initialFilters.searchText
        },
        page: initialFilters.page,
        limit: initialFilters.limit,
        statusFilter: initialFilters.statusFilter
      },
      success: (res) => {
        if (res && res.data && res.data.companies) {
          const rows = res.data.companies.map((item) => {
            const { _id, name, profileImage, subscriptionEndDate, models, users, status } = item;
            return {
              id: _id,
              name,
              profileImage: profileImage ? `${appConfig.url}${profileImage}` : name.charAt(0),
              models: models.map((m) => m.name),
              subscriptionEndDate: subscriptionEndDate,
              users: users.map((u) => u.email),
              status
            };
          });
          setFilteredRows(rows);
          setTotalUsers(res.data.total_companies)
        }
        setLoading(false)
      },
      fail: (err) => {
        console.log('err', err);
        setLoading(false);
        showNotification('error', err.message);
      },
    }))
  }

  const getCompanyList = () => {
    dispatch(fetchCompanies({
      url: '/companies/list',
      method: 'GET',
      navigate,
      success: (res) => {
        if (res && res.data && res.data.length > 0) {
          const formattedData = res.data.map(item => ({
            label: item.name,
            value: item._id
          }));
          setCompanyList(formattedData)
        } else {
          setCompanyList([])
        }
      },
      fail: (err) => {
        console.log("err", err)
      }
    }))
  }

  const getModelList = () => {
    dispatch(fetchModelList({
      url: '/models',
      method: 'GET',
      navigate,
      success: (res) => {
        if (res && res.data && res.data.length > 0) {
          const formattedData = res.data.map(item => ({
            label: item.name,
            value: item._id
          }));
          setModelList(formattedData)
        } else {
          setModelList([])
        }
      },
      fail: (err) => {
        console.log("err", err)
      }
    }))
  }

  useEffect(() => {
    getCompanies()
  }, [initialFilters]);

  useEffect(() => {
    getCompanyList();
    getModelList();
  }, [])

  const onActionHandle = (index, row, type) => {
    if (type === 'edit') {
      navigate(`/companies/edit-company/${row.id}`);
    } else if (type === 'delete') {
      setItemsToDelete([row.id]);
      setStatusAction('delete');
      setAlert('delete');
    } else if (type === 'archived'){
      setItemsToDelete([row.id]);
      setAlert('archive');
    } else if (type === 'active'){
      setItemsToDelete([row.id]);
      setAlert('active');
    }
  };

  const handleClose = () => {
    setAlert(false);
  };

  const handleCloseDialog = () => {
    setIsBulkDelete(false);
  };

  const handleBulkDeleteModal = (selectedItems, status) => {
    setIsBulkDelete(true)
    setItemsToDelete(selectedItems);
    setStatusAction(status);
  }

  const handleBulkDelete = (items, action) => {
    dispatch(changeCompanyStatus({
     url: "/companies/status",
     method: 'POST',
     navigate,
     data: {
      list: isBulkDelete ? itemsToDelete : items,
      status: isBulkDelete ? statusAction : action
     },
     success: () => {
       handleCloseDialog();
       handleClose();
       showNotification('success', `${(itemsToDelete?.length || items?.length) > 1 ? 'Companies' : 'Company'} ${(statusAction || action) === 'active' ? 'activated' : `${statusAction || action}d`} successfully!`)
       getCompanies();
     },
     fail: (err) => {
       showNotification('error', err.message);
     },
    }))
 }

  const columns = [
    {
      id: 'profileImage',
      numeric: false,
      disablePadding: true,
      label: '',
      type: 'logo',
      sortable: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'COMPANY NAME',
      sortable: true,
      filterType: 'checkbox',
      width: '15%'
    },
    {
      id: 'models',
      numeric: false,
      disablePadding: false,
      label: 'MODELS',
      type: "chip",
      toDisplay: 3,
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '25%'
    },
    {
      id: 'subscriptionEndDate',
      numeric: false,
      disablePadding: false,
      label: 'SUBSCRIPTION END',
      type: 'date',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '15%'
    },
    {
      id: 'users',
      numeric: false,
      disablePadding: false,
      label: 'USERS',
      type: "chip",
      toDisplay: 2,
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '25%',
      small: true
    },
    {
      id: '',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Edit' }, { title: 'Archived' }, { title: 'Active' }, { title: 'Delete' }],
      },
      width: '15%'
    },
  ];

  const headerButtons = [
    {
      text: 'New Company',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={whiteAdd} alt="add" />,
      onClick: () => {
        navigate('/companies/add-company');
      },
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: `${capitalize(isAlert ? isAlert === 'active' ? "Activate" : isAlert : "")} company`,
      variant: 'contained',
      color:'danger',
      onClick: () => isAlert ? handleBulkDelete(itemsToDelete, isAlert) : '',
    },
  ];

  const actionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseDialog },
    {
      title: `${statusAction === 'active' ? 'activate' : statusAction} companies`,
      variant: 'contained',
      onClick: handleBulkDelete,
      color: 'danger'
    },
  ];

  return (
    <>
      {(!isDataFilter && !filteredRows.length && !loading) ?  
       <NoDataFoundMessage title="No companies to display yet." content={
        <>
          <CustomTypography
            variant="subtitleS1"
            text="Start by creating a new company using the button below"
            color={theme.palette.text.primary}
          />
          <ThemeButton
            variant="contained"
            text="New Company"
            startIcon= {<img src={whiteAdd} alt="add" />}
            onClick= {() => {navigate('/companies/add-company');}}
          />
        </>
      } />  :
      <div style={{height: "100%"}}>
        <DataTable
          data={filteredRows}
          rows={filteredRows}
          columns={columns}
          onDelete={handleBulkDeleteModal}
          tableOf={['company', 'companies']}
          defaultOrderBy="calories"
          defaultOrder="asc"
          selectable
          pagination={true}
          headerButtons={headerButtons}
          updateFilteredRows={updateFilteredRows}
          initialFilters={initialFilters}
          loading={loading}
          searchFilterOpt={searchFilterOpt}
          totalData = {totalCompanies}
        />
        <CustomDialogBox
          open={isAlert}
          onClose={handleClose}
          title={`${capitalize(isAlert || "")} Company`}
          description={`Are you sure you want to ${isAlert ? isAlert === 'active' ? "activate" : isAlert : ""} this company?`}
          actions={actions}
        />
        <CustomDialogBox
          open={isBulkDelete}
          onClose={handleCloseDialog}
          title={`${statusAction === 'active' ? 'activate' : statusAction} Companies`}
          description={`Are you sure you want to ${statusAction === 'active' ? 'activate' : statusAction} ${itemsToDelete?.length > 1 ? `${itemsToDelete.length} companies` : 'this company'}?`}
          actions={actionBtns}
        />
      </div>
      }
    </>
  );
};
export default Company;

      