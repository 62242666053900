import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const fetchProfileDetails = createApiThunk('fetchProfileDetails');
export const updateProfile = createApiThunk('updateProfile');

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchProfileDetails.rejected, (state) => {
      state.isError = true;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default profileSlice.reducer;
