import React, { useEffect, useState,  } from 'react';
import PropTypes from 'prop-types';
// import { weekDays } from './constant';
import { getLastThirdWeekFromEndDate, getNoOfDaysRemainingInWeek, getPreviousSelectedDay, getThirdWeekFromStartDate } from '../../../../utils/helper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { parse } from 'date-fns';

const WeeklyDetailsTable = ({startDate, startDay, endDate, noOfWeeks}) => {
    const [tableDetails, setTableDetails] = useState({
        remainingDaysFromStartDate: '',
        nextWeekStartDate: '',
        thirdWeekStartDate: '',
        remainingDaysInEndDate: '',
        previousWeekStartDate: '',
        lastThirdWeekStartDate: '',
    });

    useEffect(()=> {
        let remainingDaysFromStartDate = getNoOfDaysRemainingInWeek(startDay, startDate);
        let remainingDaysInEndDate = getPreviousSelectedDay(startDay, endDate);
        let thirdWeekStartDate = getThirdWeekFromStartDate(remainingDaysFromStartDate.nextDay);
        let lastThirdWeekStartDate = getLastThirdWeekFromEndDate(remainingDaysInEndDate.previousDay);
        setTableDetails({
            remainingDaysFromStartDate: remainingDaysFromStartDate.daysToAdd,
            nextWeekStartDate: moment(new Date(remainingDaysFromStartDate.nextDay)).format('DD/MM/yyyy'),
            thirdWeekStartDate: moment(new Date(thirdWeekStartDate)).format('DD/MM/yyyy'),
            remainingDaysInEndDate: remainingDaysInEndDate.daysToSubtract,
            previousWeekStartDate: moment(new Date(remainingDaysInEndDate.previousDay)).format('DD/MM/yyyy'),
            lastThirdWeekStartDate: moment(new Date(lastThirdWeekStartDate)).format('DD/MM/yyyy'),
        });
    }, [startDate, startDay, endDate, noOfWeeks])
    
    return (
        <div className='page-table '>
            <div className='table-wrapper'>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Week number</TableCell>
                                <TableCell>1</TableCell>
                                <TableCell>2</TableCell>
                                <TableCell>...</TableCell>
                                <TableCell>{noOfWeeks === "1" ? '51' : '52'}</TableCell>
                                <TableCell>{noOfWeeks === "1" ? '52' : '53'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Starting dates</TableCell>
                                <TableCell>{moment(new Date(startDate)).format('DD/MM/yyyy')}</TableCell>
                                <TableCell>{tableDetails.nextWeekStartDate}</TableCell>
                                <TableCell>{tableDetails.thirdWeekStartDate + " - " + tableDetails.lastThirdWeekStartDate}</TableCell>
                                <TableCell>{tableDetails.previousWeekStartDate}</TableCell>
                                <TableCell>{moment(parse(endDate, 'dd/MM/yyyy', new Date())).format('DD/MM/yyyy')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Days in week</TableCell>
                                <TableCell>{tableDetails.remainingDaysFromStartDate}</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>7</TableCell>
                                <TableCell>{tableDetails.remainingDaysInEndDate}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default WeeklyDetailsTable

WeeklyDetailsTable.propTypes = {
    startDate: PropTypes.instanceOf(Date),
    startDay: PropTypes.string,
    endDate: PropTypes.instanceOf(Date),
    noOfWeeks: PropTypes.string,
};