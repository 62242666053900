import React from 'react';
import PropTypes from 'prop-types';
import CustomTypography from '../../components/CustomTypography';
import RadioButtonComponent from './radioButtonComponent';
import CustomTabs from '../../components/CustomTabs';
import { timeFormatOptions, timeStandardOptions, weekModelTabs } from './constant';

const GeneralSettigns = ({values, handleChange}) => {
  return (
    <div className='general-settings-wrapper'>
        <CustomTypography
            variant="headerh3"
            text="General"
        />
        <div className='input-wrapper'>
            <div className='left-input'>
                <RadioButtonComponent
                    name="timeZone"
                    label="Time Standard"
                    options={timeStandardOptions}
                    checked={values?.timeZone}
                    value={values?.timeZone?.val ? values?.timeZone?.val : values?.timeZone}
                    handleChange={handleChange}
                    className="user-roles"
                />
                <CustomTabs
                    label="Date Format"
                    tabs={weekModelTabs}
                    onChange={(name, value) => handleChange(name, value)}
                    value={values?.dateFormat || 'DD/MM/YYYY'}
                    name="dateFormat"
                />
            </div>
            <RadioButtonComponent
                name="timeFormat"
                label="Time Format"
                options={timeFormatOptions}
                checked={values?.timeFormat}
                value={parseInt(values?.timeFormat) || 24}
                handleChange={handleChange}
                className="user-roles"
            />
        </div>
    </div>
  )
}

GeneralSettigns.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
};

export default GeneralSettigns