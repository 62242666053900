import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import PropTypes from 'prop-types';
import TextInput from '../../../../components/TextInput';
import { generateUniqueId } from '../../../../utils/helper';
import CustomTooltip from '../../../../components/CustomTooltip';
import CustomTypography from '../../../../components/CustomTypography';
import typography from '../../../../theme/typography';
import SelectDropdown from '../../../../components/SelectDropdown';
import CustomCheckbox from '../../../../components/CustomCheckbox';

const allTypes = [
    {label: "Workloads", value: 'WORKLOADS'},
    {label: "Free", value: 'FREE'},
]
const outputType = [
    {label: "Date", value: 'DATE'},
    {label: "Currency", value: 'CURRENCY'},
    {label: "Number", value: 'NUMBER'},
    {label: "Percent", value: 'PERCENT'},
    {label: "Free", value: 'FREE'},
]

const GroupCard = ({data, filteredRecords, removeGroup}) => {
    const [group, setGroup] = data;
    const [, setGroupRecords] = useState([]);

    const removeRow = (index, data) => {
        let groupData = group;
        const rowsData = groupData[index].rows.filter((row) => data.rowId !== row.rowId)
        groupData[index].rows = [...rowsData];
        setGroup([...groupData]);
    }

    const handleChangeGroupType = (e, i) => {
        const groupData = group;
        groupData[i].type = e ? e.target.value : null;
        setGroup([...groupData])
    }
    
    const handleNameChange = (e, i, rowindex) => {
        const groupData = group;
        if(rowindex !== undefined){
            groupData[i].rows[rowindex].name = e.target.value;
        } else {
            groupData[i].name = e.target.value;
        }
        setGroup([...groupData])
    }

    const handleChangeFineTuning = (e, i, rowindex) => {
        const groupData = group;
        groupData[i].rows[rowindex].fineTuning = e.target.checked;
        setGroup([...groupData])
    }

    const handleChangeOutputType = (e, i, rowindex) => {
        const groupData = group;
        groupData[i].rows[rowindex].outputType = e.target.value;
        setGroup([...groupData])
    }

    const addRow = (index) => {
        const groupData = group;
        const newRowData = {
            _id: generateUniqueId(),
            name: '',
            rowId: `${groupData[index].groupLetter}${groupData[index].rows.length + 1}`,
            outputType : "NUMBER",
            fineTuning : false,
            operation: {
                type: "OPERATOR",
                operator: "",
                isSubChild: false,
                variableOne: {},
                variableTwo: {},
            },
        }
        groupData[index].rows = [...groupData[index].rows, newRowData];
        setGroup([...groupData]);
    }

    useEffect(() => {
        if(group){
            setGroupRecords(group)
        }
    }, [group])

    return (
        <div className='group-card-wrapper'>
            {
                filteredRecords.map((item, index) => (
                    <div className="group_card" key={item._id}>
                        <div className='flex-container'>
                            <IconButton aria-label="delete" size='small' onClick={() => removeGroup(item)}>
                                <RemoveRoundedIcon />
                            </IconButton>
                            <TextInput
                                placeholder="Group Name"
                                className="text-input-height field"
                                onInputChange={(e) => handleNameChange(e, index)}
                                value={item.name}
                            />
                            <div className='group-type-field'>
                                <CustomTypography text="Group Type" style={typography.subtitle2} />
                                <SelectDropdown
                                    label={"Group Type"}
                                    width="20%"
                                    classNameForFormControl="custom-min-width"
                                    items={allTypes}
                                    handleChange={(e) => handleChangeGroupType(e, index)}
                                    name="group-type"
                                    value={item.type}
                                />
                            </div>
                        </div>
                        <div className="group-rows-wrapper">
                            {
                                item.rows.length > 0 &&
                                item.rows.map((row, rowindex) => (
                                    <div key={row._id} className='group-row'>
                                        <div className='row-id'>
                                            <CustomTypography text={row.rowId} style={typography.subtitle2} />
                                        </div>
                                        <div className='row-items'>
                                            <div className='row-name-field'>
                                                <TextInput
                                                    placeholder="Row Name"
                                                    className="text-input-height field"
                                                    onInputChange={(e) => handleNameChange(e, index, rowindex)}
                                                    value={item.rows[rowindex].name}
                                                />
                                            </div>
                                            <SelectDropdown
                                                width="20%"
                                                classNameForFormControl="custom-min-width"
                                                items={outputType}
                                                handleChange={(e) => handleChangeOutputType(e, index, rowindex)}
                                                name="output-type"
                                                value={row.outputType}
                                            />
                                        </div>
                                        <CustomCheckbox
                                            key={row._id}
                                            label={'Apply FT'}
                                            name={'apply_ft'}
                                            checked={row?.fineTuning}
                                            handleChange={(e) => handleChangeFineTuning(e, index, rowindex)}
                                        />
                                        {
                                            row?.type !== 'subtotal' &&
                                            <IconButton aria-label="delete" size='small' onClick={() => removeRow(index, row)} className='remove-row-btn'>
                                                <RemoveRoundedIcon />
                                            </IconButton>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className='add-row-btn'>
                            <CustomTooltip
                                title="Add Row"
                                component={
                                    <IconButton aria-label="delete" size='small' onClick={() => addRow(index)}>
                                        <AddRoundedIcon />
                                    </IconButton>
                                }
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

GroupCard.propTypes = {
    data: PropTypes.array,
    filteredRecords: PropTypes.array,
    removeGroup: PropTypes.func
};

export default GroupCard