import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFT, updateFT } from '../../redux/slice/model';
import { useNavigate, useParams } from 'react-router-dom';
import importIcon from '../../assets/images/common/importIcon.svg';
import EditableTable from '../../components/DataGrid/EditableTable';
import ThemeButton from '../../components/ThemeButton';
import CustomTypography from '../../components/CustomTypography';
import CustomDialogBox from '../../components/CustomDialogBox';
import { isEqual } from '../../utils/helper';
import { useNotification } from '../../utils/NotificationProvider';
import ActionModal from '../../components/ActionModal';
import UploadFiles from '../../components/UploadFiles';
import { parse } from 'papaparse';
import ftDailyTemp from '../../assets/templates/ft-temp-daily.csv';
import ftWeeklyTemp from '../../assets/templates/ft-temp-weekly.csv';
import ftWeekly53Temp from '../../assets/templates/ft-temp-weekly-53.csv';
import TextInput from '../../components/TextInput';

const FineTuning = () => {
  const [fineTuningData, setFineTuningData] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modelId, setModelId] = useState('');
  const [budgetType, setBudgetType] = useState('');
  const [modelWeeks, setModelWeeks] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [count, setCount] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const showNotification = useNotification();

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '10%',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '10%',
    },
  ];

  useEffect(() => {
    setModelId(get(params, 'id', ''));
  }, [params]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.trim() === '') {
      setFilteredData(fineTuningData);
    } else {
      const filtered = fineTuningData.filter(
        (item) =>
          item.no.toString().toLowerCase().includes(query) ||
          item.name.toLowerCase().includes(query) 
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    setFilteredData(fineTuningData);
  }, [fineTuningData]);

  const addBudgetColumns = () => {
    let extraColumns = [];

    for (let i = 1; i <= count; i++) {
      extraColumns.push({
        id: `wd-${i}`,
        label: `${i}`,
        numeric: true,
        disablePadding: false,
        sortable: true,
        width: '50px',
        type: 'inputOnClick',
        // endAdornment: '%',
        validInputType: 'number',
      });
    }
    setUpdatedColumns([...columns, ...extraColumns]);
  };

  useEffect(() => {
    addBudgetColumns();
  }, [count]);

  useEffect(() => {
    if (budgetType === 'weekly') {
      setCount(modelWeeks);
    } else {
      setCount(365);
    }
  }, [budgetType, modelWeeks]);

  const fetchFT = () => {
    setLoading(true);
    dispatch(
      getFT({
        url: `/hierarchies/finetuning/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          setBudgetType(get(res, 'data.model.budgetType', ''));
          setModelWeeks(get(res, 'data.model.numberOfSplit'));
          if (get(res, 'data.stores') && get(res, 'data.stores').length > 0) {
            const ftRows = get(res, 'data.stores', []);
            const rows = ftRows.map((item) => {
              const { _id, no, name, fineTuning } = item;
              let transformmedData = {
                id: _id,
                no,
                name,
              };
              fineTuning.forEach((value, index) => {
                transformmedData[`wd-${index + 1}`] = value;
                transformmedData.inputType = 'input';
              });
              return transformmedData;
            });
            setFineTuningData([...rows]);
            setPrevData(JSON.parse(JSON.stringify(rows)));
          }
          setLoading(false);
        },
        fail: (err) => {
          console.log('err', err);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (modelId) {
      fetchFT();
    }
  }, [modelId]);

  const handleUpdateDetails = (updatedDetails) => {
    const rows = updatedDetails
      ? JSON.parse(JSON.stringify(updatedDetails))
      : fineTuningData;
    setFineTuningData([...rows]);
  };

  const onSubmit = () => {
    const modifiedData = [];
    const formattedData = (arr) => {
      const rowData = arr.map((item) => {
        const updatedStore = { ...item };

        Object.keys(updatedStore).forEach((key) => {
          if (key.startsWith('wd')) {
            updatedStore[key] = parseFloat(updatedStore[key]);
          }
        });
        return updatedStore;
      });
      return rowData;
    };

    formattedData(fineTuningData).forEach((row) => {
      const matchingPrev = formattedData(prevData).find(
        (prev) => prev.id === row.id
      );
      if (matchingPrev) {
        if (!isEqual(row, matchingPrev)) {
          modifiedData.push(row);
        }
      }
    });

    const transformmedData = modifiedData.map((item) => {
      const ft = Object.keys(item)
        .filter((key) => key.startsWith('wd-'))
        .sort((a, b) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]))
        .map((key) => parseFloat(item[key]));
      let requestedObj = {};
      requestedObj = {
        storeId: item.id,
        fineTuning: ft,
      };
      return requestedObj;
    });
    if (modifiedData && modifiedData.length > 0) {
      dispatch(
        updateFT({
          url: '/hierarchies/finetuning',
          method: 'PUT',
          data: {
            model: modelId,
            stores: transformmedData,
          },
          navigate,
          success: () => {
            showNotification('success', 'Fine tuning applied successfully!');
            navigate(-1);
          },
          fail: (err) => {
            console.log('FT err', err);
          },
        })
      );
    } else {
      navigate(-1);
    }
  };

  const handleImportData = () => {
    if (!uploadedFile) return;
    parse(uploadedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data;
        const updatedData = fineTuningData.map((store) => {
          const importedRow = parsedData.find(
            (row) =>
              row.no === String(store.no) &&
              row?.name?.trim()?.toLowerCase() ===
                store?.name?.trim()?.toLowerCase()
          );

          if (importedRow) {
            const mergedStore = { ...store };
            for (let i = 1; i <= (budgetType === 'weekly' ? 52 : 365); i++) {
              const key = `wd-${i}`;
              mergedStore[key] = importedRow[key] ? importedRow[key] : 100;
            }
            return mergedStore;
          } else {
            return store;
          }
        });

        const filteredData = updatedData.filter((store) => store !== null);
        setFineTuningData([...filteredData]);
        handleCloseUploadModal();
      },
    });
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const onBack = () => {
    navigate(-1);
    setIsAlert(false);
  };

  const actions = [
    { title: 'Continue', variant: 'outlined', onClick: handleCloseAlert },
    {
      title: 'Cancel changes',
      variant: 'contained',
      onClick: onBack,
      color: 'danger',
    },
  ];

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleCloseUploadModal = (event, reason) => {
    if (reason === 'backdropClick') return;
    else {
      setIsUploadModal(false);
      setUploadedFile(null);
    }
  };

  const uploadActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseUploadModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <>
      <div style={{ height: '100%' }} className='fine-tuning-step'>
        <div className="step-header flex-container">
          <CustomTypography variant="headerh3" text="Fine Tuning" />
          <ThemeButton
            text="Import Fine Tuning"
            variant="outlined"
            onClick={() => setIsUploadModal(true)}
            startIcon={<img src={importIcon} alt="importIcon" />}
          />
        </div>
        <div style={{ padding: '8px 24px 10px' }}>
          <TextInput
            placeholder="Search"
            onInputChange={handleSearch}
            value={searchQuery}
            width="15%"
          />
        </div>
        <EditableTable
          columns={updatedColumns}
          rows={filteredData}
          data={filteredData}
          tableOf={['finetuning']}
          showStatusTabs={false}
          defaultOrder="asc"
          showTableHeader={false}
          updateFilteredRows={(updatedDetails) =>
            handleUpdateDetails(updatedDetails.data)
          }
          loading={loading}
        />
        <div>
          <div className="divider" />
          <div className="company-frame flex-container form-title">
            <ThemeButton
              text="Cancel"
              variant="text"
              onClick={() => setIsAlert(true)}
            />
            <ThemeButton
              text="Apply Changes"
              variant="contained"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
      <ActionModal
        open={isUploadModal}
        close={handleCloseUploadModal}
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={
              budgetType === 'weekly'
                ? modelWeeks === 52
                  ? ftWeeklyTemp
                  : ftWeekly53Temp
                : ftDailyTemp
            }
            additionalNote={[
              '1. All fields must contain strictly numerical values, except for the name fields.',
              "2. Ensure that the 'no' and 'name' fields match those defined in the company hierarchy for accurate data import.",
              '3. Only fill in the fields where you want to adjust the fine-tuning percentage. Any fields left blank will default to 100%.',
            ]}
          />
        }
        actionButtons={uploadActionBtns}
        title="Upload Files"
        className="upload-model-wrapper"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleCloseAlert}
        title="Cancel Changes?"
        description="Are you sure you want to leave?"
        actions={actions}
        width="100%"
      />
    </>
  );
};

export default FineTuning;