import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import importIcon from '../../../../assets/images/common/importIcon.svg';
import plusCircle from '../../../../assets/images/common/plusCircle.svg';
import minusCircle from '../../../../assets/images/common/minusCircleSmall.svg';
import trash from '../../../../assets/images/common/trash.svg';
import copy from '../../../../assets/images/common/copy.svg';
import edit from '../../../../assets/images/common/edit.svg';
import CustomTooltip from '../../../../components/CustomTooltip';
import './styles.scss';
import { generateUniqueId, validateInput } from '../../../../utils/helper';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import ActionModal from '../../../../components/ActionModal';
import CopyRole from './CopyRole';
import { required, validateField } from '../../../../utils/Validator';
import { getHierarchy, getRoles } from '../../../../redux/slice/model';
import { useDispatch } from 'react-redux';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import AddRole from './AddRole';
import UploadFiles from '../../../../components/UploadFiles';
import { parse } from 'papaparse';
import roleTemplate from '../../../../assets/templates/role-template.csv';
import roleExImg from '../../../../assets/images/common/roleExImg.png';
import TableSkeleton from '../../../../components/DataGrid/TableSkeleton';
import { rolesRules } from '../modelRules';

const Roles = ({ modelId, formData, setFormData, completedSteps, loading, validationRules, setValidationRules }) => {
  const [roles, setRoles] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [isDeleteStoreAlert, setIsDeleteStoreAlert] = useState(false)
  const [roleId, setRoleId] = useState();
  const [storeId, setStoreId] = useState();
  const [copyRole, setCopyRole] = useState({});
  const [isCopyModal, setIsCopyModal] = useState(false);
  const [isAddRoleModal, setIsAddRoleModal] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [copiedRow, setCopiedRow] = useState(null);
  const [errors, setErrors] = useState({});
  const [allStores, setAllStores] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newRole, setNewRole] = useState({
    roleName: '',
    stores: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStepCompleted = get(completedSteps, 'role', false);

  const modalRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  const duplicateRoleRule = (message, isEditRole = false, currentRoleId = null) => (value) => {
    const rolesList = roles
      .filter((item) => item.type !== "DELETE_ROLE" && (!isEditRole || item.id !== currentRoleId))
      .map((r) => r.roleName);
    if (rolesList.some((role) => value?.toLowerCase() === role?.toLowerCase())) {
      return message;
    }
  };

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      role: rolesRules
    })
  }, [])

  useEffect(() => {
    setFormData({
      ...formData,
      role: {
        groups: [...roles],
        prevData: prevData,
      },
    });
  }, [roles, prevData]);

  const fetchRoles = () => {
    setIsLoading(true);
    dispatch(
      getRoles({
        url: `/roles/getBymodelId/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.groups') && get(res, 'data.groups').length > 0) {
            const roles = get(res, 'data.groups', []);
            const rows = roles.map((item) => {
              const { _id, name, stores } = item;
              const roleStores = (stores && stores.length) > 0 ? stores?.map((st) => {
                const { _id, prePayRise, postPayRise, storeId, FTEHours, ...rest } = st;
                return {
                  ...rest,
                  id: _id,
                  name: allStores?.find((store) => store.storeId === storeId)?.name || '',
                  no: allStores?.find((store) => store.storeId === storeId)?.no || '',
                  payPrePayRise: prePayRise,
                  payPostPayRise: postPayRise,
                  hours: FTEHours,
                  inputType:'input',
                  storeId,
                };
              }) : [];
              return {
                id: _id,
                roleName: name,
                stores: roleStores,
              };
            });
            setRoles([...rows]);
            setPrevData(JSON.parse(JSON.stringify(rows)));
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('err', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (isStepCompleted) {
      fetchRoles();
    }
  }, [isStepCompleted, allStores]);

  const handleCloseCopyModal = () => {
    setIsCopyModal(false);
    setErrors({});
  };

  const handleCloseUploadModal = (event, reason) => {
    if(reason === "backdropClick")
    return;  
    else{
      setUploadModal(false);
      setUploadedFile(null);
    }
  };

  const handleOpenAddRoleModal = () => {
    setIsAddRoleModal(true);
    setNewRole({
      ...newRole,
      stores: storeList,
    });
  };

  const handleCloseAddRoleModal = () => {
    setIsAddRoleModal(false);
    setNewRole({
      roleName: '',
      stores: [],
    });
    setErrors({});
    setIsEditRole(false)
  };

  const onDelete = (id) => {
    setRoleId(id);
    setIsAlert(true);
  };

  const onCopy = (roleData) => {
    setCopiedRow(roleData);
    setIsCopyModal(true);
    setCopyRole({});
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const handleCloseStoreAlert = () => {
    setIsDeleteStoreAlert(false);
  }

  const handleDeleteRole = () => {
    const updatedRoles = roles
      .filter((item) => item.type !== 'NEW_ROLE' || item.id !== roleId) 
      .map((item) => {
        if (item.id === roleId && item.type !== 'NEW_ROLE') {
          return {
            ...item,
            type: 'DELETE_ROLE',
            stores: item.stores.map((st) => {
              return {
                ...st,
                type: 'DELETE_STORE',
              };
            }), 
          };
        }
        return item; 
      });
    setRoles([...updatedRoles]);
    handleClose();
  };

  const handleDeleteStore = () => {
    const updatedRoles = roles.map((role) => {
      let hasDeletedStore = false;
  
      const updatedStores = role.stores
        .filter((item) => item.type !== 'NEW_STORE' || item.id !== storeId)
        .map((item) => {
          if (item.id === storeId && item.type !== 'NEW_STORE') {
            hasDeletedStore = true; 
            return {
              ...item,
              type: 'DELETE_STORE',
            };
          }
          return item;
        });

      if (hasDeletedStore) {
        return {
          ...role,
          stores: updatedStores,
          type: role.type !== 'NEW_ROLE' ? 'UPDATE_ROLE' : role.type,
        };
      } else {
        return {
          ...role,
          stores: updatedStores,
        };
      }
    });
  
    setRoles([...updatedRoles]);
    handleCloseStoreAlert();
  };
  

  const handleUpdate = (role) => {
    setIsEditRole(true);
    setIsAddRoleModal(true);
    const { roleName, stores, id } = role;
    const roleDetails = stores?.map((r) => {
      return {
        label: `${r.name} (${r.no})`,
        value: r.storeId,
        id: id,
        type: r.type,
      };
    });
    const filteredStores = storeList.filter((store) =>
      roleDetails
        .filter((st) => st.type !== 'DELETE_STORE')
        .some((newStores) => newStores.value === store.value)
    );
    const editRole = {
      id,
      roleName,
      stores: filteredStores,
    };
    setNewRole(editRole);
  };

  const handleCopyRole = () => {
    const copyRoleValidRules = {
      newRole: [...modalRules, duplicateRoleRule('Role with same name already exist')],
    };
    if (!validateField(copyRole, copyRoleValidRules, setErrors)) return;
    const newRow = {
      ...copiedRow,
      id: generateUniqueId(),
      roleName: copyRole.newRole,
      type: "NEW_ROLE",
      stores: copiedRow.stores.filter((st) => st.type !== 'DELETE_STORE').map((item) => {
        return {
          ...item,
          id: generateUniqueId(),
          type: "NEW_STORE",
          inputType: 'input'
        };
      }),
    };
    setRoles([...roles, newRow]);
    handleCloseCopyModal();
    setErrors({});
  };

  const handleSaveRole = () => {
    let addRoleValidRules = {}
    const errorMsg = 'Role with same name already exists'
    if (isEditRole) {
      addRoleValidRules.roleName = [
        ...modalRules,
        duplicateRoleRule(errorMsg, true, newRole.id),
      ];
    } else {
      addRoleValidRules.roleName = [
        ...modalRules,
        duplicateRoleRule(errorMsg),
      ];
    }
    
    if (!validateField(newRole, addRoleValidRules, setErrors)) return;

    const filteredRows = allStores.filter((store) =>
      newRole.stores.some((newStore) => newStore.value === store.storeId)
    );

    const roleDetails = (roleId) => {
      const prevRole = prevData.find((role) => role.id === roleId);
      const prevStores = prevRole ? prevRole.stores : [];
      return [
        ...filteredRows.map((item) => {
          const existingStore = prevStores.find(
            (store) => store.storeId === item.storeId
          );
          if (existingStore) {
            return { ...existingStore };
          } else {
            return {
              ...item,
              id: generateUniqueId(),
              type: 'NEW_STORE',
              inputType: 'input',
            };
          }
        }),
        ...prevStores
          .filter(
            (prevStore) =>
              !filteredRows.some((store) => store.storeId === prevStore.storeId)
          )
          .map((deletedStore) => ({
            ...deletedStore,
            type: 'DELETE_STORE',
          })),
      ];
    };

    if (isEditRole) {
      const allRoles = [...roles];
      const roleToEdit = allRoles.map((role) => {
        if (role.id === newRole.id) {
          if (prevData.some((r) => r.id === role.id)) {
            setIsEditRole(false)
            return {
              ...role,
              roleName: newRole.roleName,
              type: 'UPDATE_ROLE',
              stores: roleDetails(role.id)
                ? JSON.parse(JSON.stringify(roleDetails(role.id)))
                : [],
            };
          } else {
            return {
              ...role,
              roleName: newRole.roleName,
              stores: roleDetails(role.id)
                ? JSON.parse(JSON.stringify(roleDetails(role.id)))
                : [],
            };
          }
        } else {
          return { ...role };
        }
      });

      setRoles([...roleToEdit]);
    } else {
      const newRow = {
        id: generateUniqueId(),
        roleName: newRole.roleName,
        type: 'NEW_ROLE',
        stores: roleDetails() ? JSON.parse(JSON.stringify(roleDetails())) : [],
      };
      setRoles((prevRoles) => [...prevRoles, newRow]);
    }

    setIsEditRole(false);
    handleCloseAddRoleModal();
  };

  const onRoleExpand = (id) => {
    setRoles(
      roles.map((role) =>
        role.id === id ? { ...role, showDetails: !role.showDetails } : role
      )
    );
  };

  const handleUpdateDetails = (roleId, updatedDetails) => {
    const rows = updatedDetails
      ? JSON.parse(JSON.stringify(updatedDetails))
      : [];
    setRoles(
      roles.map((role) =>
        role.id === roleId
          ? {
              ...role,
              type: role.type === 'NEW_ROLE' ? 'NEW_ROLE' : 'UPDATE_ROLE',
              stores: [...rows],
            }
          : role
      )
    );
  };

  const fetchStores = () => {
    setIsLoading(true);
    dispatch(
      getHierarchy({
        url: `/hierarchies/get/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.stores') && get(res, 'data.stores').length > 0) {
            const rows = res.data.stores;
            const stores = rows.map(({ no, name, _id }) => ({
              label: `${name} (${no})`,
              value: _id,
            }));
            const storeData = rows.map(({ no, name, _id }) => ({
              storeId: _id,
              no,
              name,
            }));
            setStoreList(stores);
            setAllStores(storeData);
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('err stores', err);
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setIsDeleteStoreAlert(true);
      setStoreId(row.id);
    }
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO',
      sortable: true,
      filterType: 'checkbox',
      width: '8%',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '20%',
    },
    {
      id: 'hours',
      numeric: false,
      disablePadding: false,
      label: 'HOURS',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      type: 'inputOnClick',
      validInputType: 'number',
    },
    {
      id: 'payRiseWeek',
      numeric: false,
      disablePadding: false,
      label: 'PAYRISE WEEK',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      type: 'inputOnClick',
      validInputType: 'number',
    },
    {
      id: 'payRise',
      numeric: false,
      disablePadding: false,
      label: 'PAYRISE %',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      validInputType: 'number',
      type: 'inputOnClick',
    },
    {
      id: 'payPrePayRise',
      numeric: false,
      disablePadding: false,
      label: 'PAY PRE PAYRISE',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      validInputType: 'number',
      type: 'inputOnClick',
    },
    {
      id: 'payPostPayRise',
      numeric: false,
      disablePadding: false,
      label: 'PAY POST PAYRISE',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      validInputType: 'number',
      type: 'inputOnClick',
    },
    // {
    //   id: 'rule',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'RULE',
    //   sortable: true,
    //   searchFilter: true,
    //   filterType: 'checkbox',
    //   width: '7%',
    //   small: true,
    //   validInputType: 'number',
    //   type: 'input',
    // },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  const handleImportData = () => {
    if (!uploadedFile) return;
    parse(uploadedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data;

        const existingRolesMap = new Map();
        roles.forEach((role) => {
          existingRolesMap.set(role.roleName, role);
        });
        let currentRole = null; 
  
        parsedData.forEach((row) => {
          const {
            roleName,
            no,
            name,
            hours,
            payRiseWeek,
            payRise,
            payPrePayRise,
            // rule
          } = row;
  
          if (roleName) {
            currentRole = existingRolesMap.get(roleName);
            if (currentRole) {
  
              if (currentRole.type !== 'NEW_ROLE') {
                currentRole.type = 'UPDATE_ROLE';
              }
            } else {
              currentRole = {
                id: generateUniqueId(),
                roleName,
                type: 'NEW_ROLE',
                stores: [],
                showDetails: true,
              };
              roles.push(currentRole);
              existingRolesMap.set(roleName, currentRole);
            }
          }
  
          if (!currentRole) return;
          if (no && name && isStoreMatched(no, name)) {
            const storeId = getStoreId(no, name);
            if (!storeId) return;

            const existingStore = currentRole.stores.find(
              (store) => store.no === parseInt(no, 10) && store.name.toLowerCase() === name.toLowerCase()
            );
  
            if (existingStore) {
  
              if (hours) existingStore.hours = hours;
              if (payRiseWeek) existingStore.payRiseWeek = payRiseWeek <= 53 ? payRiseWeek : '';
              if (payRise) existingStore.payRise = payRise;
              if (payPrePayRise) existingStore.payPrePayRise = payPrePayRise;
              if (hours && payRise) existingStore.payPostPayRise = Number(payPrePayRise) + (Number(payPrePayRise) * Number(payRise)/100);
              // if (rule) existingStore.rule = rule;
              if (existingStore.type !== 'NEW_STORE') {
                existingStore.type = 'UPDATE_STORE';
              }
            } else {
              const newStore = {
                storeId,
                no: parseInt(no, 10),
                name,
                id: generateUniqueId(),
                type: 'NEW_STORE',
                inputType: 'input',
                hours: hours || "",
                payRiseWeek: payRiseWeek <= 53 ? payRiseWeek : '',
                payRise: payRise || "",
                payPrePayRise: payPrePayRise || "",
                payPostPayRise: Number(payPrePayRise) + (Number(payPrePayRise) * Number(payRise)/100) || "",
                // rule: rule || "",
              };
              currentRole.stores.push(newStore);
            }
          }
        });
  
        setRoles([...roles]);
        handleCloseUploadModal();
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      },
    });
  };

  const isStoreMatched = (no, name) => {
    return allStores.some(
      (store) => store.no === parseInt(no, 10) && store.name.toLowerCase() === name.toLowerCase()
    );
  };
  
  const getStoreId = (no, name) => {
    const store = allStores.find(
      (store) => store.no === parseInt(no, 10) && store.name.toLowerCase() === name.toLowerCase()
    );
    return store ? store.storeId : null;
  };

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete role',
      variant: 'contained',
      onClick: handleDeleteRole,
      color: 'danger',
    },
  ];

  const deleteStoreActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseStoreAlert },
    {
      title: 'Delete store',
      variant: 'contained',
      onClick: handleDeleteStore,
      color: 'danger',
    },
  ];

  const copyActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseCopyModal },
    {
      title: 'Copy',
      variant: 'contained',
      onClick: handleCopyRole,
    },
  ];

  const addRoleActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseAddRoleModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveRole,
    },
  ];

  const uploadActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseUploadModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <>
    {(loading || isLoading) ? <TableSkeleton rows={roles.length || 6} columns={3}/> : <div style={{height: '100%'}}>
      <div className="form-title flex-container">
        <CustomTypography variant="headerh3" text="Roles" />
        <div className="d-flex g-8">
          <ThemeButton
            text="Add roles"
            variant="contained"
            onClick={handleOpenAddRoleModal}
          />
          <ThemeButton
            text="Import roles"
            variant="outlined"
            startIcon={<img src={importIcon} alt="importIcon" />}
            onClick={() => setUploadModal(true)}
          />
        </div>
      </div>
      <div className='roles-table'>
        <div className="role-header d-flex">
          <CustomTypography text="" style={{ width: '5%' }} />
          <CustomTypography text="ROLE NAME" style={{ width: '90%' }} />
        </div>
        {roles && roles.length > 0 ? (
          roles
            .filter((r) => r.type !== 'DELETE_ROLE')
            .map((role) => {
              return (
                <div key={role.id} className="role-table-wrapper">
                  <div
                    className="role-container g-8 d-flex-center"
                    style={{
                      backgroundColor: `${role.showDetails ? '#E9EDF5' : 'white'}`,
                    }}
                  >
                    <div style={{ width: '4.5%' }}>
                      <CustomTooltip
                        title="Role stores"
                        component={
                          <button
                            type="button"
                            className="icon-btn btn-secondary m-0"
                            onClick={() => onRoleExpand(role.id)}
                          >
                            <img
                              src={role.showDetails ? minusCircle : plusCircle}
                              alt="expand"
                            />
                          </button>
                        }
                      />
                    </div>
                    <CustomTypography
                      text={role.roleName}
                      style={{ width: '90%', fontSize: '14px' }}
                    />
                    <div className="d-flex" >
                      <CustomTooltip
                        title="Copy"
                        component={
                          <button
                            type="button"
                            className="icon-btn btn-secondary"
                            onClick={() => onCopy(role)}
                          >
                            <img src={copy} alt="copy" />
                          </button>
                        }
                      />
                      <CustomTooltip
                        title="Edit"
                        component={
                          <button
                            type="button"
                            className="icon-btn btn-secondary"
                            onClick={() => handleUpdate(role)}
                          >
                            <img src={edit} alt="edit" />
                           </button>
                        }
                      />
                      <CustomTooltip
                        title="Delete"
                        component={
                          <button
                            type="button"
                            className="icon-btn btn-secondary"
                            onClick={() => onDelete(role.id)}
                          >
                            <img src={trash} alt="delete" />
                          </button>
                        }
                      />
                    </div>
                  </div>
                  {role.showDetails && (
                    <EditableTable
                      columns={columns}
                      rows={role.stores.filter(
                        (st) => st.type !== 'DELETE_STORE'
                      )}
                      data={role.stores}
                      tableOf={['stores']}
                      showStatusTabs={false}
                      defaultOrderBy="calories"
                      defaultOrder="asc"
                      updateFilteredRows={(updatedDetails) =>
                        handleUpdateDetails(role.id, updatedDetails.data)
                      }
                      showTableHeader={false}
                      loading={loading || isLoading}
                    />
                  )}
                </div>
              );
            })
        ) : (
          <CustomTypography
            variant="subtitle2"
            text="Click the button above to add roles."
            style={{marginLeft: '16px'}}
          />
        )}
      </div>
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete role"
        description="Are you sure you want to delete this role?
                       Please note, this action is irreversible."
        actions={actions}
      />
      <CustomDialogBox
        open={isDeleteStoreAlert}
        onClose={handleCloseStoreAlert}
        title="Delete store"
        description="Are you sure you want to delete this store?
                       Please note, this action is irreversible."
        actions={deleteStoreActionBtns}
      />
      <ActionModal
        open={isCopyModal}
        close={handleCloseCopyModal}
        component={
          <CopyRole
            copyRole={copyRole}
            setCopyRole={setCopyRole}
            errors={errors}
          />
        }
        actionButtons={copyActionBtns}
        title="Copy Role"
        className="upload-model"  
      />
      <ActionModal
        open={isAddRoleModal}
        close={handleCloseAddRoleModal}
        component={
          <AddRole
            newRole={newRole}
            setNewRole={setNewRole}
            errors={errors}
            storeList={storeList}
          />
        }
        actionButtons={addRoleActionBtns}
        title={`${isEditRole ? "Edit" : "Add New" } Role`}
        className="upload-model"
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseUploadModal}
        component={
          <UploadFiles
            getUploadedFile={getUploadedFile}
            templateSrc={roleTemplate}
            exampleImg={roleExImg}
            additionalNote={[
              '1. Refer to the example image of the Role import sheet to understand how to fill it correctly.',
              '2. Please note that only rows where the `no` and `name` fields match those defined in the company hierarchy will be imported.',
              '3. All fields must contain strictly numerical values, except for the roleName and name fields.',
              '4. For new roles, include the roleName field.',
              '5. If only store details are provided (without roleName), it will be treated as role assignment data for the stores.',
              '6. The value for payRiseWeek should be in between 1 to 53.'
            ]}
          />
        }
        actionButtons={uploadActionBtns}
        title="Upload Files"
        className="upload-model-wrapper"
      />
    </div>}
    </>
  );
};

export default Roles;
