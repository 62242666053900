import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import fileImg from '../assets/images/common/file.svg';
import document from '../assets/images/common/chart.svg';
import ProgressCircle from './ProgressCircle';
import typography from '../theme/typography';
import CustomTypography from './CustomTypography';
import { allowedFileTypes } from '../utils/constants';

const UploadFiles = ({ getUploadedFile, templateSrc, additionalNote, exampleImg, className }) => {
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const progressRef = useRef(0);

useEffect(() => {
  if (uploadedFile) {
    const formData = new FormData();
    formData.append('file', uploadedFile);

    let lastUpdateTime = null;
    let accumulatedTime = 0;
    const animateProgress = (timestamp) => {
      if (!lastUpdateTime) lastUpdateTime = timestamp;

      const deltaTime = timestamp - lastUpdateTime;
      accumulatedTime += deltaTime;

      if (accumulatedTime >= 250) {
        setProgress((prevProgress) => {
          const newProgress = Math.min(prevProgress + 10, 100);
          progressRef.current = newProgress;
          return newProgress;
        });
        accumulatedTime = 0;
      }

      lastUpdateTime = timestamp;
      if (progressRef.current < 100) {
        requestAnimationFrame(animateProgress);
      } else {
        setUploadedFile(uploadedFile);
        getUploadedFile(uploadedFile);
      }
    };
    requestAnimationFrame(animateProgress);
  }
}, [uploadedFile]); 
  
  const handleFileUpload = (file) => {
    if (allowedFileTypes.includes(file.type)) {
      setUploadedFile(file);
    } else {
      alert('Only CSV files are allowed.');
    }
  };

  const onInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <div
      className={`upload-file-wrapper ${className}`}
    >
      <div className="image-upload-wrapper">
        <div>
          <CustomTypography text="Step 1" style={typography.bodyB2}/>
          <div style={typography.subtitle2}>Click <a href={templateSrc} download>here</a> to download the latest version of the import template.</div>
        </div>
        <div>
          <CustomTypography text="Step 2" style={typography.bodyB2}/>
          <CustomTypography text="Populate the downloaded file following the instructions if any (at bottom)." style={typography.subtitle2}/>
        </div> 
        <div>
        <CustomTypography text="Step 3" style={typography.bodyB2}/>
        <CustomTypography text="Once is ready, Click below to select your populated file." style={typography.subtitle2}/>
        </div>
        <div className="border-box upload-file d-flex-center">
          {progress > 0 ? (
            <ProgressCircle
              variant="determinate"
              value={progress}
              sx={{ marginTop: '5px' }}
              // showText={true}
            />
          ) : (
            <>
              <label htmlFor="raised-button-file">
                <div className="upload-file-container">
                  <div className="upload-file-img">
                    <div className="icon_wrapper file">
                      <img src={fileImg} />
                    </div>
                    <div className="icon_wrapper chart">
                      <img src={document} />
                    </div>
                  </div>
                  <div className="d-flex">
                    <span
                      className="upload-file-btn"
                      style={typography.subtitle2}
                    >
                      Select a CSV file
                    </span>
                    &nbsp;
                    <CustomTypography
                      text="to upload"
                      style={typography.bodyB2}
                    />
                  </div>
                  {/* <CustomTypography
                    text="CSV, Excel, OFX, QIF, XML or TXT"
                    style={typography.overline}
                  /> */}
                </div>
              </label>
              <input
                name=""
                // ref={fileInputRef}
                accept=".csv"
                id="raised-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={onInputChange}
              />
            </>
          )}
        </div>
      </div>
      <div className="divider"></div>
      {additionalNote && additionalNote.length > 0 && 
        <div className='bg-white note-container'>
          {additionalNote.map((item, i) => {
            return (
              <div key={i}>
              <span style={typography.bodyB2}>{item}</span>
              <br />
              </div>
            )
          })
          }
        </div>
      }
      {exampleImg && 
        <div className='bg-white note-container'>
          <img src={exampleImg} alt="example Img" />
        </div>
      }
      <div>
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};
export default UploadFiles;
