import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <Box className="d-flex-center" style={{height: 'inherit'}}>
         <CircularProgress color="primary" />
        </Box>
    )
}

export default Loading;