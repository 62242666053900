import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../components/TextInput';
import { Box, Typography } from '@mui/material';
import SelectDropdown from '../../../../../components/SelectDropdown';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import moment from 'moment';
import { ValueTypes } from './constant';
import { get } from 'lodash';

const AddDailyParameter = ({
  parameter,
  setParameter,
  allGroupsData,
  selectedGroup,
  setSelectedGroup,
  editParameter,
  errors,
}) => {
  const [groupTypes, setGroupTypes] = useState([]);

  useEffect(() => {
    const existingGroups = allGroupsData?.map((item) => ({
      label: item.groupName,
      value: item.id,
    }));
    setGroupTypes(existingGroups);
  }, [allGroupsData]);

  useEffect(() => {
    if (editParameter) {
      setParameter({
        id: editParameter.id,
        groupId: editParameter.groupId,
        parameterName: editParameter.parameterName || '',
        notes: editParameter.notes || '',
        group: editParameter.group || '',
        type: editParameter.type || '',
        groupName: editParameter.group || '',
        value: editParameter.value || ['', '', '', '', '', '', ''],
      });
      setSelectedGroup(editParameter.groupId);
    } else {
      setParameter({
        ...parameter,
        value: ['', '', '', '', '', '', ''],
      });
    }
  }, [editParameter]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'value') {
      const newValue = [...parameter.value];
      newValue[index] = value;
      setParameter({
        ...parameter,
        value: newValue,
      });
    } else {
      setParameter({
        ...parameter,
        [name]: value,
      });
    }
  };

  const handleGroupChange = (event) => {
    const groupName = event.target.value;
    if (groupTypes.some((g) => g.label?.toLowerCase() === groupName?.toLowerCase())){
      const group = groupTypes.find((g) => g.label?.toLowerCase() === groupName?.toLowerCase()).value;
      setSelectedGroup(group);
    } else {
      setSelectedGroup(event.target.value);
    }
  };

  const checkInputType = () => {
    return (
      parameter?.type === 2 || parameter?.type === 3 || parameter?.type === 4
    );
  };

  const renderInput = () => {
    return (
      <div>
        {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day, index) => (
          <div
            key={day}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography variant="caption" sx={{ color: 'black' }}>
                {day}
              </Typography>
            </div>
            <div style={{ width: '70%', marginRight: '10px' }}>
              {(() => {
                switch (parameter?.type) {
                  case 1:
                    return (
                      <CustomDatePicker
                        placeholder="29/08/2023"
                        value={get(parameter, `value[${index}]`, '')}
                        helperText={Array.isArray(errors?.value) ? errors?.value[index] : errors?.value}
                        onChange={(date) =>
                          handleChange(
                            {
                              target: {
                                name: 'value',
                                value: moment(new Date(date)).format(
                                  'YYYY-MM-DD'
                                ),
                              },
                            },
                            index
                          )
                        }
                      />
                    );
                  default:
                    return (
                      <TextInput
                        type={checkInputType() ? 'number' : 'text'}
                        placeholder=""
                        value={get(parameter, `value[${index}]`, '')}
                        onChange={(e) => handleChange(e, index)}
                        className="text-input-height"
                        name="value"
                        helperText={Array.isArray(errors?.value) ? errors?.value[index] : errors?.value}
                        width="100%"
                      />
                    );
                }
              })()}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="parameter-modal-container">
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Parameter name
        </Typography>
        <TextInput
          placeholder="Parameter name"
          value={parameter?.parameterName}
          onChange={handleChange}
          className="text-input-height"
          name="parameterName"
          helperText={errors.parameterName}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Notes (optional)
        </Typography>
        <TextInput
          placeholder="Notes (optional)"
          value={parameter?.notes}
          onChange={(e) => handleChange(e)}
          className="text-input-height"
          name="notes"
        />
      </Box>
      <Box mt={2}>
        <div className="flex-container" style={{ alignItems: 'center', gap: '8px' }}>
          <TextInput
            label="Group"
            className="bg-white w-100"
            placeholder={`Create a new group ${groupTypes && groupTypes.length > 0 ? 'or select' : ''}`}
            width="-webkit-fill-available"
            value={groupTypes.find((item) => item.value === selectedGroup)?.label || selectedGroup}
            name="group"
            onInputChange={handleGroupChange}
            helperText={errors.selectedGroup}
          />
          {groupTypes && groupTypes.length > 0 && <div>
            <div style={errors.selectedGroup ? {height: '5px'} : {height: '25px'}} />
          <SelectDropdown
            classNameForFormControl="m-0 bg-white"
            items={groupTypes}
            handleChange={handleGroupChange}
            value={selectedGroup}
            name="group"
          />
          </div>}
        </div>
      </Box>
      <Box mt={2}>
        <Typography variant="caption" sx={{ color: 'black' }}>
          Value
        </Typography>

        <div className="parameter-input-wrapper">
          {renderInput()}
          <SelectDropdown
            name="type"
            value={parameter?.type}
            items={ValueTypes || []}
            handleChange={handleChange}
            helperText={errors?.type}
            minWidth="100%"
          />
        </div>
      </Box>
    </div>
  );
};

export default AddDailyParameter;
