   import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import TextInput from '../../components/TextInput';
import CustomRadioButton from '../../components/CustomRadioButton';
import ThemeButton from '../../components/ThemeButton';
import CustomDialogBox from '../../components/CustomDialogBox';
import TransferList from '../../components/TransferList';
import { required, validateField } from '../../utils/Validator';
import { useNotification } from '../../utils/NotificationProvider';
import { addUser, fetchUser, updateUser } from '../../redux/slice/users';
import CustomTypography from '../../components/CustomTypography';
import { fetchPermissions } from '../../redux/slice/permissions';
import { isPhoneValid, isValidEmail, validateInput, validatePassword } from '../../utils/helper';
import typography from '../../theme/typography';
import Loader from '../../components/Loader';
import { fetchCompanies } from '../../redux/slice/company';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { appConfig } from '../../config/appConfig';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import eye from '../../assets/images/login/eye.svg';
import eyeOff from '../../assets/images/login/eyeOff.svg'

const validationRules = {
  firstName: [required('First name is required'), validateInput('First name is required')],
  lastName: [required('Last name is required'), validateInput('Last name is required')],
  email: [required('Email is required'), isValidEmail('Invalid email address')],
  phone: [required('Phone number is required'), isPhoneValid('Invalid phone number', 'US')],
  password: [validatePassword()]
};

const AddEditUser = (props) => {
  const { isModal, onAddUser, setAddUser, setIsModal, setIsModalCancel, setNewUser } = props;
  const [values, setValues] = useState({});
  const [leftItems, setLeftItems] = useState([]);
  const [loading, setLoading] = useState(false)
  const [rightItems, setRightItems] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [previousCompanies, setPreviousCompanies] = useState([]);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [errors, setErrors] = useState({});
  const isEditMode = params.action === 'edit-user';
  const loggedUserRole = localStorage.getItem('role');
  const roleWisePermissions = useSelector(
    (state) => state.permissions.data.data
  );

  const roleOptions = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'User/Approver' },
    { value: 3, label: 'User' },
  ];

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  const getPermissions = (data) => {
    setLoading(true)
    dispatch(
      fetchPermissions({
        url: '/users/permission',
        method: 'GET',
        navigate,
        success: (res) => {
          if (res && res.data && res.data.length > 0) {
            if (!isEditMode) {
              setLeftItems(res.data[1]);
            } else {
              setLoading(true)
              const { firstName, lastName, role, email, phone, permissions, companies } =
                data;
              const commonPermissions = permissions
                .map((permission) => {
                  return res.data[role].find((p) => p.value === permission);
                })
                .filter((p) => p !== undefined);
              const ungrantedPermissions = res.data[role].filter(
                (p) => !permissions.includes(p.value)
              );
              const row = {
                firstName,
                lastName,
                role,
                email,
                phone,
                companies,
                grantedPermissions: commonPermissions,
                ungrantedPermissions: ungrantedPermissions,
              };
              getCompanies(row)
              setLoading(false)
            }
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  const getUser = () => {
    setLoading(true)
    dispatch(
      fetchUser({
        url: `/users/profile/${params.id}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (res && res.data) {
            setPreviousCompanies(res.data.companies)
            getPermissions(res.data);
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  const getCompanies = (data) => {
    setLoading(true)
    dispatch(
      fetchCompanies({
        url: `/companies/list`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (res && res.data && res.data.length > 0) {
            const formattedData = res.data.map(item => ({
              label: item.name,
              value: item._id
            }));
            setCompanyList(formattedData)
            if (isEditMode) {
              const selectedCompanies = data.companies.map((item) => item._id);
              const filteredCompanies = formattedData.filter(company =>
                selectedCompanies.includes(company.value))
              const row = {
                ...data,
                companies: filteredCompanies
              }
              setValues(row)
              setRightItems(row.grantedPermissions)
              setLeftItems(row.ungrantedPermissions)
            }
          } else {
            setCompanyList([])
            if (isEditMode) {
              setValues(data)
            }
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  useEffect(() => {
    if (!isEditMode) {
      setValues({
        ...values,
        grantedPermissions: rightItems,
      });
    }
  }, [rightItems]);

  useEffect(() => {
    if (!isEditMode) {
      setValues({
        ...values,
        role: roleOptions[0].value,
        grantedPermissions: rightItems,
      });
      getPermissions();
      getCompanies()
    } else {
      getUser();
    }
  }, []);

  useEffect(() => {
    setRightItems([]);
    if (roleWisePermissions && roleWisePermissions.length > 0) {
      setLeftItems(roleWisePermissions[values?.role]);
    }
  }, [values.role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  if (name === 'password') {
    if (value?.trim()) {
      const passwordError = validatePassword()(value);
      if (passwordError){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: passwordError,
        }));
      } else {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[name]; 
          return updatedErrors;
        });
      }
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; 
        return updatedErrors;
      });
    }
    setValues({
      ...values,
      [name]: value,
    });
  } else {
    setValues({
    ...values,
    [name]: value,
  });
}
  };

  const handlePhoneChange = (val, name) => {
    setValues({
      ...values,
      [name]: val,
    });
  };

  const handleAutocomplete = (event, newValue) => {
    setValues(prevValues => ({
      ...prevValues,
      companies: newValue
    }));
  };


  const handleClose = () => {
    setIsPasswordReset(false);
  };

  const onBack = () => {
    navigate(-1);
    setIsAlert(false);
  };

  const handleSuccess = (message) => {
    showNotification('success', message);
    if (isModal) {
      setIsModal(false)
      setIsModalCancel(false)
    } else {
      onBack();
    }
  };

  const handleFailure = (message) => {
    const msg = message || 'Something went wrong';
    showNotification('error', msg);
  };

  const onSubmit = () => {
    isModal && setAddUser(false)
    if (!validateField(values, validationRules, setErrors, errors)) return;
    const previousIds = previousCompanies.map(company => company?._id);
    const currentIds = values.companies?.map(company => company?.value);
    const requestedPayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      role: values.role,
      addCompanies: values.companies?.map((item) => item.value) || [],
      removeCompanies: previousIds.filter(id => !currentIds.includes(id)),
      permissions: rightItems.map((p) => p.value),
    };
    if (isEditMode){
      requestedPayload.changePassword = values.password?.trim() ? values.password : ''
    }
    if (isEditMode) {
      dispatch(
        updateUser({
          url: `/users/${params.id}`,
          method: 'PUT',
          navigate,
          data: requestedPayload,
          success: () => handleSuccess('User updated successfully!'),
          fail: (err) => handleFailure(err.message),
        })
      );
    } else {
      dispatch(
        addUser({
          url: '/users',
          method: 'POST',
          navigate,
          data: requestedPayload,
          success: (res) => {
            if (isModal){
              setNewUser(res.data)
            }
            handleSuccess('User added successfully!')
          },
          fail: (err) => handleFailure(err.message),
        })
      );
    }
  };

  useEffect(() => {
    if (isModal && onAddUser) {
      onSubmit()
    }
  }, [onAddUser])

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const passswordResetActions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Reset Password',
      variant: 'contained',
      onClick: handleResetPassword,
    },
  ];

  const actions = [
    { title: 'Continue', variant: 'outlined', onClick: handleCloseAlert },
    {
      title: 'Cancel changes',
      variant: 'contained',
      onClick: onBack,
      color: 'danger',
    },
  ];

  const renderAutocomplete = () => {
    return (
      <CustomAutocomplete
        name="companies"
        label="Company"
        value={values.companies}
        options={companyList}
        onChange={handleAutocomplete}
        width='70%'
        isMultiple={true}
      />
    )
  }

  return (
    <div>
      {loading ?
        (isModal ?
          <div className='user-modal-loader'>
            <Loader />
          </div>
          : <Loader />)
        :
        <Box className="form-wrapper">
          {!isModal && (
            <div className="form-title flex-space-btw-wrapper">
              <CustomTypography
                variant="headerh3"
                text={`${isEditMode ? 'Edit' : 'New'} User`}
              />
              {/* {isEditMode && (
                <ThemeButton
                  text="Reset user password"
                  variant="outlined"
                  width="auto"
                  onClick={() => setIsPasswordReset(true)}
                />
              )} */}
            </div>
          )}
          <div className={`user-container divider ${isModal && 'fd-column'}`}>
            <div className={`user-info-wrapper ${isModal && 'w-100'}`}>
              <div className={`user-info divider ${isModal && 'user-modal'}`}>
                <TextInput
                  label="First name"
                  name="firstName"
                  value={values.firstName}
                  helperText={errors.firstName}
                  onInputChange={handleChange}
                />
                <TextInput
                  label="Last name"
                  name="lastName"
                  value={values.lastName}
                  helperText={errors.lastName}
                  onInputChange={handleChange}
                />
                <TextInput
                  label="Email address"
                  name="email"
                  value={values.email}
                  helperText={errors.email}
                  onInputChange={handleChange}
                />
                <PhoneNumberInput 
                   label="Phone Number"
                   defaultValue="gb"
                   value={values.phone}
                   helperText={errors.phone}
                   onInputChange={(phone) => handlePhoneChange(phone, 'phone')}
                />
                {isEditMode && <TextInput
                  label="Change password"
                  name="password"
                  value={values.password}
                  helperText={errors.password}
                  onInputChange={handleChange}
                  type={!visibility ? 'text' : 'password'}
                  endAdornment={
                    <img
                      src={visibility ? eyeOff : eye}
                      alt="toggle visibility"
                      style={{ cursor: 'pointer' }}
                      onClick={toggleVisibility}
                    />
                  }
                />}
              </div>
              <div className="user-info-2 divider">
                <CustomRadioButton
                  name="role"
                  label="Role"
                  options={loggedUserRole === '0' ? appConfig.userRoles : roleOptions}
                  checked={values.role}
                  value={parseInt(values.role)}
                  handleChange={handleChange}
                  className="user-roles"
                />
              </div>
              <div className="user-info-2">
                {isEditMode ? (values.companies && renderAutocomplete()) : renderAutocomplete()}
              </div>
            </div>
            <div className={`user-permission-wrapper ${isModal && 'w-100'}`} style={isModal && { paddingLeft: '0px' }}>
              <CustomTypography style={typography.subtitle2} text="Permissions" className="mb-10"/>
              <div className="user-permissions">
                <TransferList
                  leftItems={leftItems}
                  rightItems={rightItems}
                  setLeftItems={setLeftItems}
                  setRightItems={setRightItems}
                  className="all-permissions"
                />
              </div>
            </div>
          </div>

          {!isModal && (
            <div className="company-frame flex-container form-title">
              <ThemeButton
                text="Cancel"
                variant="text"
                onClick={() => setIsAlert(true)}
              />
              <ThemeButton
                text={isEditMode ? 'Save changes' : 'Add user'}
                variant="contained"
                onClick={onSubmit}
              />
            </div>
          )}
          <CustomDialogBox
            open={isPasswordReset}
            onClose={handleClose}
            title="Reset user password"
            description="You are about to reset the password for this user. An email will be send to the user email address."
            actions={passswordResetActions}
          />
          <CustomDialogBox
            open={isAlert}
            onClose={handleCloseAlert}
            title="Cancel Changes?"
            description="Are you sure you want to leave? Please note, any progress made so far will be lost."
            actions={actions}
          />
        </Box>}
    </div>
  );
};

AddEditUser.propTypes = {
  isModal: PropTypes.func,
};

export default AddEditUser;
