import dashboard from "../assets/images/sidebar/dashboard.svg";
import company from "../assets/images/sidebar/company.svg";
import model from "../assets/images/sidebar/model.svg";
import report from "../assets/images/sidebar/report.svg";
import audit from "../assets/images/sidebar/audit.svg";
import users from "../assets/images/sidebar/users.svg";

const menuItems = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: dashboard,
  },
  {
    name: "Company",
    url: "/companies",
    icon: company,
  },
  {
    name: "Models",
    url: "/models",
    icon: model,
  },
  {
    name: "Reports",
    url: "/reports",
    icon: report,
  },
  {
    name: "Users",
    url: "/users",
    icon: users,
  },
  {
    name: "Audit",
    url: "/audits",
    icon: audit,
  },
];

export default menuItems;
