import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import CustomDatePicker from "../../components/CustomDatePicker";
import ThemeButton from "../../components/ThemeButton";
import AddEditUser from "../users/addEditForm";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CustomDialogBox from "../../components/CustomDialogBox";
import ActionModal from "../../components/ActionModal";
import ImageUpload from "../../components/ImageUpload";
import TextInput from "../../components/TextInput";
import CustomTypography from "../../components/CustomTypography";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../redux/slice/users";
import { required, validateField } from "../../utils/Validator";
import { addCompany, fetchCompany, updateCompany } from "../../redux/slice/company";
import { useNotification } from "../../utils/NotificationProvider";
import Loader from "../../components/Loader";
import { appConfig } from "../../config/appConfig";
import palette from "../../theme/palette";
import typography from "../../theme/typography";
import { validateInput } from "../../utils/helper";
import moment from "moment";
import { get } from "lodash";

const validationRules = {
  companyName: [required('Company name is required'), validateInput('Company name is required')],
  subscriptionEndDate: [required('Subscription end date is required')],
};

const AddEditCompany = () => {
  const [isModal, setIsModal] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalCancel, setIsModalCancel] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [userList, setUserList] = useState([])
  const [previousUsers, setPreviousUsers] = useState([])
  const [newUser, setNewUser] = useState()
  const showNotification = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const isEditMode = params.action === "edit-company";

  useEffect(() => {
    let companyUsers = get(values, 'users', []);
    if (get(newUser, 'firstName') && get(newUser, 'lastName') && get(newUser, '_id')){
       companyUsers.push({
        label: `${newUser.firstName} ${newUser.lastName}`, value: newUser._id
       })
    }
    setValues({
      ...values,
      users: companyUsers
    })
    setNewUser()
  }, [newUser])

  const onBack = () => {
    navigate(-1);
    setIsAlert(false);
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const handleCloseModal = () => {
    setIsModalCancel(true)
    setIsModal(false)
    setAddUser(false)
  }

  const handleAddUser = () => {
    setAddUser(true)
  }

  const handleChange = (name, val) => {
    if (name === "maxBudget" && val < 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Maximum budget cannot be negative",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
      setValues({
        ...values,
        [name]: val,
      });
    }
  };

  const handleAutocomplete = (event, newValue) => {
    setValues(prevValues => ({
      ...prevValues,
      users: newValue
    }));
  };

  const getUserList = (data) => {
    setLoading(true)
    dispatch(fetchUsers({
      url: '/users/list',
      method: 'GET',
      navigate,
      success: (res) => {
        if (res && res.data && res.data.length > 0) {
          const formattedData = res.data.map(item => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item._id
          }));
          if (isEditMode) {
            setValues({
              ...data,
              users: formattedData.filter((item) => data.users.includes(item.value))
            })
          }
          setUserList(formattedData)
        } else {
          if (isEditMode) {
            setValues(data)
          }
          setUserList([])
        }
        setLoading(false)
      },
      fail: (err) => {
        console.log("err", err)
        setLoading(false)
      }
    }))
  }

  const getCompany = () => {
    setLoading(true)
    dispatch(fetchCompany({
      url: `/companies/${params.id}`,
      method: 'GET',
      navigate,
      success: (res) => {
        if (res && res.data) {
          const { name, subscriptionEndDate, maxBudgetRunsWeekly, profileImage, users } = res.data;
          const row = {
            companyName: name,
            subscriptionEndDate,
            maxBudget: maxBudgetRunsWeekly,
            companyLogo: profileImage && `${appConfig.url}${profileImage}`,
            users
          }
          setPreviousUsers(users);
          getUserList(row)
        }
        setLoading(false)
      },
      fail: (err) => {
        console.log("err", err)
        setLoading(false)
      }
    }))
  }

  const handleSuccess = (message) => {
    showNotification('success', message);
    onBack();
  };

  const handleFailure = (message) => {
    const msg = message || 'Something went wrong';
    showNotification('error', msg);
  };

  const onSubmit = () => {
    if (!validateField(values, validationRules, setErrors)) return;
    const currentIds = values.users?.map(user => user?.value);
    const requestedPayload = {
      name: values.companyName,
      uniqueName: values.companyName,
      subscriptionEndDate: values.subscriptionEndDate ? moment(new Date(values.subscriptionEndDate)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')  : null,
      maxBudgetRunsWeekly: values.maxBudget,
      profileImage: isEditMode && typeof values?.companyLogo === 'string' ? (values?.companyLogo?.startsWith(appConfig.url) ? values?.companyLogo?.replaceAll(appConfig.url, '') : values.companyLogo) : values.companyLogo,
      addUsers: values.users?.map((item) => item.value) || [],
      removeUsers: previousUsers.filter(id => !currentIds.includes(id)),
    };
    if (isEditMode) {
      dispatch(
        updateCompany({
          url: `/companies/${params.id}`,
          method: 'PUT',
          navigate,
          contentType: 'multipart/form-data',
          data: requestedPayload,
          success: () => handleSuccess('Company updated successfully!'),
          fail: (err) => handleFailure(err.message),
        })
      );
    } else {
      dispatch(
        addCompany({
          url: '/companies',
          method: 'POST',
          navigate,
          contentType: 'multipart/form-data',
          data: requestedPayload,
          success: () => handleSuccess('Company added successfully!'),
          fail: (err) => handleFailure(err.message),
        })
      );
    }
  };

  useEffect(() => {
    if (!isModal && !isModalCancel) {
      if (isEditMode) {
        getCompany();
      } else {
        getUserList();
      }
    }
  }, [isModal])

  const actions = [
    { title: "Continue", variant: "outlined", onClick: handleCloseAlert },
    {
      title: "Cancel changes",
      variant: "contained",
      onClick: onBack,
      color: "danger",
    },
  ];

  const actionButtons = [
    { title: "Cancel", variant: "outlined", onClick: handleCloseModal },
    {
      title: "Add User",
      variant: "contained",
      onClick: handleAddUser,
    },
  ];

  const renderAutocomplete = () => {
    return (
      <CustomAutocomplete
        isMultiple={true}
        name="users"
        label="Users"
        options={userList}
        value={values.users}
        width='40%'
        onChange={handleAutocomplete} />
    )
  }

  return (
    <div>
      {loading ? <Loader /> : <Box className="form-wrapper">
        <div className="form-title">
          <CustomTypography
            variant="headerh3"
            text={`${isEditMode ? 'Edit' : 'New'} Company`}
          />
        </div>
        <div className="company-form-div">
          <div className="company-frame">
            <TextInput
              label="Name"
              name="companyName"
              value={values.companyName}
              helperText={errors.companyName}
              onInputChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              sizeSmall />
            <CustomDatePicker
              className="datepicker-div"
              title="Subscription end date"
              value={values.subscriptionEndDate}
              helperText={errors.subscriptionEndDate}
              name="subscriptionEndDate"
              disablePast
              onChange={(date) => handleChange("subscriptionEndDate", date)}
            />
            <TextInput
              label={<>Maximum budget runs per week <span style={{ color: palette.heading.light }}>(optional)</span></>}
              name="maxBudget"
              type="number"
              value={values.maxBudget}
              helperText={errors.maxBudget}
              placeholder="Unlimited"
              onInputChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              sizeSmall
            />
          </div>
          <div className="company-frame upload-image-container">
            <div>
            <CustomTypography style={typography.subtitle2} text="Profile image" className="mb-10" />
            <ImageUpload
              name="companyLogo"
              uploadedFile={values.companyLogo}
              handleFileUpload={(e) => handleChange(e.target.name, e.target.files[0])}
            />
            </div>
            {/* <div>
              <Typography variant="subtitle1">Other options</Typography>
              <CustomCheckbox
                label="Skore"
                name="skore"
                checked={values.skore}
                handleChange={(e) => {
                  handleChange(e.target.name, e.target.checked);
                }}
              />
              <CustomCheckbox
                label="AI Drivers"
                name="aiDrivers"
                checked={values.aiDrivers}
                handleChange={(e) => {
                  handleChange(e.target.name, e.target.checked);
                }}
              />
            </div> */}
          </div>
          <div className="company-frame custom-pd">
            {isEditMode ? (values.users && renderAutocomplete()) : renderAutocomplete()}
            <ThemeButton
              text="New user"
              variant="text"
              style={{ marginTop: "25px" }}
              onClick={() => setIsModal(true)}
              startIcon={<AddRoundedIcon />}
            />
          </div>
        </div>
        <div className="company-frame flex-space-btw-wrapper form-title">
          <ThemeButton
            text="Cancel"
            variant="text"
            onClick={() => setIsAlert(true)}
          />
          <ThemeButton
            text={`${isEditMode ? "Save changes" : "New company"}`}
            variant="contained"
            onClick={onSubmit}
          />
        </div>
        <CustomDialogBox
          open={isAlert}
          onClose={handleCloseAlert}
          title="Cancel changes?"
          description="Are you sure you want to leave?
                       Please note, any progress made so far will be lost."
          actions={actions}
        />
        <ActionModal
          open={isModal}
          close={handleCloseModal}
          component={<AddEditUser setNewUser={setNewUser} isModal={isModal} onAddUser={addUser} setIsModalCancel={setIsModalCancel} setAddUser={setAddUser} setIsModal={setIsModal} />}
          actionButtons={actionButtons}
          title="New User"
          width="40%"
          className="new-user-modal"
        />
      </Box>}
    </div>
  );
};

export default AddEditCompany;
