import { useEffect } from "react";
import AppRoutes from "../routes";
import Header from "./header";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../utils/helper";

const MainLayout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    if (isTokenExpired(token)){
      navigate('/login');
      localStorage.removeItem('token');
    }
  }, [token]);

  return (
    <div>
      <div>
        <Sidebar />
        <Header />
        <main className="main-wrapper">
          <AppRoutes />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
