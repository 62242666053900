import React from 'react';
import PropTypes from 'prop-types';
import CustomTypography from '../../components/CustomTypography'
import TextInput from '../../components/TextInput';
import PhoneNumberInput from '../../components/PhoneNumberInput';

const BasicInfo = ({values, errors, handleChange, handlePhoneChange}) => {
  return (
    <div className='basic-info-wrapper'>
        <CustomTypography
            variant="headerh3"
            text="Basic information"
        />
        <div className='input-wrapper'>
            <TextInput
                label="First name"
                name="firstName"
                type='text'
                value={values?.firstName}
                helperText={errors.firstName}
                onInputChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <TextInput
                label="Last name"
                name="lastName"
                type='text'
                value={values?.lastName}
                helperText={errors.lastName}
                onInputChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <TextInput
                label="Email address"
                name="email"
                value={values?.email}
                helperText={errors.email}
                onInputChange={(e) => handleChange(e.target.name, e.target.value)}
                disabled
            />
            <PhoneNumberInput
                   label="Phone Number"
                   defaultValue="gb"
                   value={values?.phone}
                   helperText={errors.phone}
                   onInputChange={(phone) => handlePhoneChange(phone, 'phone')}
                />
        </div>
    </div>
  )
}


BasicInfo.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handlePhoneChange: PropTypes.func
};

export default BasicInfo