import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slice/users';
import permissionsReducer from './slice/permissions';
import modelReducer from './slice/model';
import profileReducer from './slice/profile';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    permissions: permissionsReducer,
    model: modelReducer,
    profile: profileReducer
  },
});
