import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import collapseStepper from '../assets/images/common/alignLeft.svg';
import ExpandStepper from '../assets/images/common/alignRight.svg';
import stepCompleted from '../assets/images/stepper/stepCompleted.svg';
import { PropTypes } from 'prop-types';
import './styles.scss';
import CustomTypography from './CustomTypography';
import typography from '../theme/typography';
import CustomChip from './CustomChip';
import palette from '../theme/palette';

const VerticalStepper = ({ activeStep, setActiveStep, steps, expandMenu, setExpandMenu }) => {

  const handleReset = () => {
    setActiveStep(0);
  };

  const getChipStyle = (status) => {
    switch (status) {
      case 'completed':
        return {
          color: '#20C374',
          backgroundColor: '#E6FBF1',
        };
      case 'inProgress':
        return {
          color: '#864293',
          backgroundColor: '#F6F4FD',
        };
      case 'pending':
        return {
          color: '#BDBDBD',
          backgroundColor: 'transparent',
          border: '1px solid #BDBDBD',
        };
      default:
        return 'inherit';
    }
  };

  const getDescriptionColor = (status) => {
    switch (status) {
      case 'completed':
        return palette.alerts.success;
      case 'inProgress':
        return palette.text.secondary;
      case 'pending':
        return '#868FA0';
      default:
        return '';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'completed':
        return 'Completed';
      case 'inProgress':
        return 'In Progress';
      case 'pending':
        return 'Pending';
      default:
        return '';
    }
  };

  const handleNarrowMenu = () => {
    setExpandMenu(false);
  };

  const handleExapndMenu = () => {
    setExpandMenu(true);
  };
  return (
    <div className="vertical-stepper-container">
      {expandMenu ? (
        <Box className="stepper-wrapper">
          <img
            src={collapseStepper}
            className="stepper-button"
            onClick={handleNarrowMenu}
          />
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.id} className="steps-wrapper">
                <StepLabel
                  StepIconComponent={
                    step.status === 'completed' &&
                    (() => <img src={stepCompleted} alt="completed" />)
                  }
                >
                  <CustomChip
                    singleChip={getStatusLabel(step.status)}
                    className="stepper-chip"
                    style={getChipStyle(step.status)}
                  />
                  <CustomTypography
                    text={step.description}
                    style={{
                      ...typography.overline,
                      color: getDescriptionColor(step.status),
                    }}
                    className="t-uppercase"
                  />
                </StepLabel>
                <StepContent></StepContent>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      ) : (
        <Box className="stepper-wrapper stepper-wrapper-narrow">
          <img
            src={ExpandStepper}
            className="stepper-button"
            onClick={handleExapndMenu}
          />
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.id}>
                <StepLabel
                  StepIconComponent={
                    step.status === 'completed' &&
                    (() => <img src={stepCompleted} alt="completed" />)
                  }
                />
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </div>
  );
};

VerticalStepper.propTypes = {
  activeStep: PropTypes.any.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
};

export default VerticalStepper;
