// import { colors } from "@mui/material";

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  white,
  black,
  primary: {
    main: '#864293',
    dark: '#864293',
    light: '#864293',
  },
  text: {
    primary: '#687182',
    secondary: '#464F60',
    link: '#864293',
    light: '#A1A9B8'
  },
  background: {
    default: '#F0EFF0',
    paper: white,
    primary: '#864293',
    secondary: '#DEDADD',
  },
  heading: {
    default: '#464F60',
    light: '#A1A9B8',
    dark: '#1A2240',
    grey: '#687182'
  },
  alerts: {
    success: '#008649',
    danger: '#D60E41',
    info: '#E3E3E3',
  },
  danger: {
    contrastText: white,
    dark: '#D60E41',
    main: '#D60E41',
    light: '#D60E41',
  },
  chipColor: {
    contrastText: "#5E5ADB",
    dark: "#E9EDF5",
    main: "#E9EDF5",
    light: "#E9EDF5",
  },
  activeChip: {
    contrastText: "#289f64",
    dark: "#e9f5ed",
    main: "#e9f5ed",
    light: "#e9f5ed",
  },
  archiveChip: {
    contrastText: "#d60e41",
    dark: "#ffe5e5",
    main: "#ffe5e5",
    light: "#ffe5e5",
  },
  draftChip: {
    contrastText: "#ff9800",
    dark: "#fff0d5",
    main: "#fff0d5",
    light: "#fff0d5",
  },
};

export default palette;
