import React from 'react';
import TextInput from '../../../../components/TextInput';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';

const AddRole = ({ newRole, setNewRole, errors, storeList }) => {

  const handleChange = (name, value) => {
    setNewRole((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutocomplete = (event, newValue) => {
    setNewRole((prevValues) => ({
      ...prevValues,
      stores: newValue,
    }));
  };

  return (
    <div className="pd-24">
      <TextInput
        label="Role name "
        name="roleName"
        value={newRole.roleName}
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        mb="15px"
        helperText={errors.roleName}
      />
      <CustomAutocomplete
        name="stores"
        label="Location"
        value={newRole.stores}
        options={storeList}
        onChange={handleAutocomplete}
        width="100%"
        height="160px"
        isMultiple={true}
      />
    </div>
  );
};

export default AddRole;
