import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import SelectDropdown from '../../../../components/SelectDropdown';
import ThemeButton from '../../../../components/ThemeButton';
import TextInput from '../../../../components/TextInput';
import typography from '../../../../theme/typography';
import CustomTypography from '../../../../components/CustomTypography';

const ChangeRelative = ({
  changeRelativeData,
  setChangeRelativeData,
  driverList,
  budgetType,
  errors,
  storeCR,
  storeRow,
  drivers,
  modelWeeks
}) => {
  const [fromWeekOpt, setFromWeekOpt] = useState([]);
  const [driverName, setDriverName] = useState('');

  useEffect(() => {
    const findDriverForStore = (store, drivers) => {
      for (let driver of drivers) {
        const foundStore = driver.stores.find((s) => s.id === store.id);
        if (foundStore) {
          return driver.name;
        }
      }
    };
    const dName = findDriverForStore(storeRow, drivers);
    setDriverName(dName);
  }, [storeRow, drivers]);

  useEffect(() => {
    const generateOptions = (num, label) => {
      const options = [{ label: `All ${label}s`, value: 'all' }];
      for (let i = 1; i <= num; i++) {
        options.push({ label: i.toString(), value: i });
      }
      return options;
    };

    if (budgetType === 'weekly') {
      setFromWeekOpt(generateOptions(modelWeeks, 'week'));
    } else if (budgetType === 'daily') {
      setFromWeekOpt(generateOptions(365, 'day'));
    }
  }, [budgetType]);

  const handleAutocomplete = (event, newValue) => {
    setChangeRelativeData((prevValues) => ({
      ...prevValues,
      drivers: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeRelativeData({
      ...changeRelativeData,
      [name]: value,
    });
  };
  const handleIncrease = () => {
    setChangeRelativeData((prevValues) => ({
      ...prevValues,
      calculationVal: prevValues.calculationVal
        ? Number(prevValues.calculationVal) + 1
        : 1,
    }));
  };

  const handleDecrease = () => {
    setChangeRelativeData((prevValues) => ({
      ...prevValues,
      calculationVal: prevValues.calculationVal !== undefined && prevValues.calculationVal !== null
        ? Number(prevValues.calculationVal) - 1
        : 0,
    }));
  };

  const handleCalculationVal = (val) => {
    setChangeRelativeData({
      ...changeRelativeData,
      calculationVal: val,
    });
  };

  return (
    <div className="pd-24 d-flex fd-column g-8">
      {!storeCR ? (
        <div>
          <CustomAutocomplete
            name="drivers"
            label="Drivers"
            value={changeRelativeData.drivers}
            options={driverList}
            onChange={handleAutocomplete}
            width="100%"
            style={{ padding: '16px' }}
            isMultiple={true}
          />
          {errors.drivers && (
            <span className="error-txt">{errors.drivers}</span>
          )}
        </div>
      ) : (
        <div className="flex-container">
          <CustomTypography
            text={`Driver: ${driverName}`}
            style={typography.subtitle2}
          />
          <CustomTypography
            text={`Store: ${storeRow.name}`}
            style={typography.subtitle2}
          />
        </div>
      )}
      <div className="divider" />
      <div className="flex-container" style={{ gap: '16px', padding: '16px' }}>
        <ThemeButton
          text="-10%"
          variant="outlined"
          onClick={() => handleCalculationVal(-10)}
        />
        <ThemeButton
          text="-5%"
          variant="outlined"
          onClick={() => handleCalculationVal(-5)}
        />
        <ThemeButton
          text="+5%"
          variant="outlined"
          onClick={() => handleCalculationVal(5)}
        />
        <ThemeButton
          text="+10%"
          variant="outlined"
          onClick={() => handleCalculationVal(10)}
        />
      </div>
      <div className="divider" />
      <div className="flex-space-btw-wrapper g-8" style={{ padding: '8px', alignItems: 'start' }}>
        <ThemeButton
          text="Decrease %"
          variant="contained"
          onClick={handleDecrease}
          style={{ marginTop: '6px' }}
        />
        <TextInput
          name="calculationVal"
          value={changeRelativeData.calculationVal}
          onInputChange={handleChange}
          type={'number'}
          width="40%"
          helperText={errors.calculationVal}
        />
        <ThemeButton
          text="Increase %"
          variant="contained"
          onClick={handleIncrease}
          style={{ marginTop: '6px' }}
        />
      </div>
      <div className="divider" />
      <div style={{ padding: '8px' }}>
        <CustomTypography
          text={`Apply from ${budgetType === 'weekly' ? 'week' : 'day'}`}
          style={typography.subtitle2}
        />
        <SelectDropdown
          label="Select action"
          name="applyFrom"
          handleChange={handleChange}
          items={fromWeekOpt}
          value={changeRelativeData.applyFrom}
          minWidth="100%"
        />
      </div>
    </div>
  );
};

export default ChangeRelative;