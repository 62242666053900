import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const Timer = ({ time, setTime, ...props }) => {
  const startTimeRef = useRef(Date.now());
  const remainingTimeRef = useRef(time);
  const requestRef = useRef(null);

  useEffect(() => {
    const updateTimer = () => {
      const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
      const newTimeLeft = remainingTimeRef.current - elapsed;

      if (newTimeLeft > 0) {
        setTime(newTimeLeft);
        requestRef.current = requestAnimationFrame(updateTimer);
      } else {
        setTime(0); 
        cancelAnimationFrame(requestRef.current);
      }
    };

    requestRef.current = requestAnimationFrame(updateTimer);
    return () => cancelAnimationFrame(requestRef.current);
  }, [setTime]);

  const formatTime = (rawTime) => {
    const minutes = Math.floor(rawTime / 60);
    const seconds = rawTime % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <Box {...props} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
      <Typography variant="label">{formatTime(time)}</Typography>
    </Box>
  );
};

export default Timer;
