export const budgetTypeOpt = [
  { value: '1', label: 'Weekly' },
  { value: '2', label: 'Daily' },
];

export const budgetTypes = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'daily', label: 'Daily' },
];

export const weekModelTabs = [
  { value: '1', label: '52 weeks', title: 52},
  { value: '2', label: '53 weeks', title: 53 },
];

export const modelWeeks = [
  { value: 52, label: '52 weeks'},
  { value: 53, label: '53 weeks'},
];

export const reportingPeriodsTabs = [
  { value: '1', label: 'Monthly' },
  { value: '2', label: '4 4 5' },
  { value: '3', label: 'Quarterly' },
];

export const currencyOpt = [
  { value: 'gbp', label: 'GBP' },
  { value: 'eur', label: 'EUR' },
  { value: 'usd', label: 'USD' },
];

export const weekDays = [
  { value: '2', label: 'Monday' },
  { value: '3', label: 'Tuesday' },
  { value: '4', label: 'Wednesday' },
  { value: '5', label: 'Thursday' },
  { value: '6', label: 'Friday' },
  { value: '7', label: 'Saturday' },
  { value: '1', label: 'Sunday' },
];

export const weekStartDays = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];
