export const globalSettingRules = [
  { field: 'fyName', isRequired: true, errorMessage: 'FY Name is required' },
  {
    field: 'budgetType',
    isRequired: true,
    errorMessage: 'Budget type is required',
  },
  {
    field: 'budgetStartDate',
    isRequired: true,
    errorMessage: 'Budget start date is required',
  },
  {
    field: 'modelWeeks',
    isRequired: true,
    errorMessage: 'Model Week is required',
  },
  {
    field: 'parameters',
    nested: true,
    nestedFields: [
      {
        field: 'groupName',
        isRequired: true,
        errorMessage: 'Group Name is required',
      },
      {
        field: 'parameters',
        nested: true,
        nestedFields: [
          {
            field: 'value',
            isRequired: true,
            errorMessage: 'Value is required',
          },
          {
            field: 'parameterName',
            isRequired: true,
            errorMessage: 'Parameter Name is required',
          },
        ],
      },
    ],
  },
];

export const companyHierarchyRules = [
  {
    field: 'stores',
    nested: true,
    nestedFields: [
      {
        field: 'no',
        isRequired: true,
        errorMessage: 'Store number is required',
      },
      {
        field: 'name',
        isRequired: true,
        errorMessage: 'Store name is required',
      },
      {
        field: 'startDate',
        isRequired: true,
        errorMessage: 'Store startDate is required',
      },
      {
        field: 'endDate',
        isRequired: true,
        errorMessage: 'Store endDate required',
      },
    ],
  },
  {
    field: 'level',
    nested: true,
    nestedFields: [
      { field: 'value', isRequired: true, errorMessage: 'Please set levels.' },
    ],
  },
];

export const rolesRules = [
  {
    field: 'groups',
    nested: true,
    nestedFields: [
      {
        field: 'roleName',
        isRequired: true,
        errorMessage: 'Role name is required',
      },
      {
        field: 'stores',
        nested: true,
        nestedFields: [
          {
            field: 'hours',
            isRequired: true,
            errorMessage: 'Hour is required',
          },
          {
            field: 'payRiseWeek',
            isRequired: true,
            errorMessage: 'Pay rise week is required',
          },
          {
            field: 'payRise',
            isRequired: true,
            errorMessage: 'Pay rise is required',
          },
          {
            field: 'rule',
            isRequired: true,
            errorMessage: 'Rule is required',
          },
        ],
      },
    ],
  },
];

export const SMVRules = [
  {
    field: 'tasks',
    nested: true,
    nestedFields: [
      {
        field: 'name',
        isRequired: true,
        errorMessage: 'Task name is required',
      },
      {
        field: 'basicMinutes',
        isRequired: true,
        errorMessage: 'Basic minute is required',
      },
      {
        field: 'contingencyAllowance',
        isRequired: true,
        errorMessage: 'Contingency allowance is required',
      },
      {
        field: 'relaxationAllowance',
        isRequired: true,
        errorMessage: 'Relaxation allowance is required',
      },
      {
        field: 'standardMinuteValue',
        isRequired: true,
        errorMessage: 'Standard minute value is required',
      },
      {
        field: 'unitOfMeasure',
        isRequired: true,
        errorMessage: 'Unit of measure is required',
      },
      {
        field: 'startDate',
        isRequired: true,
        errorMessage: 'Start date is required',
      },
      {
        field: 'endDate',
        isRequired: true,
        errorMessage: 'End date is required',
      },
    ],
  },
];
