import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../views/dashboard";
import Company from "../views/company";
import Users from "../views/users";
import Report from "../views/report";
import Audit from "../views/audit";
import Model from "../views/model";
import AddEditUser from "../views/users/addEditForm";
import AddEditCompany from "../views/company/addEditForm";
import AddEditModel from "../views/model/addEditModel";
import Profile from "../views/profile";
import FineTuning from "../views/model/fineTuning";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}/>
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/companies" element={<Company />}/>
      <Route path="/companies/:action?/:id?" element={<AddEditCompany />}/>
      <Route path="/models" element={<Model />}/>
      <Route path="/models/:action?/:id?" element={<AddEditModel />}/>
      <Route path="/models/fine-tuning/:id?" element={<FineTuning />}/>
      <Route path="/reports" element={<Report />}/>
      <Route path="/audits" element={<Audit />}/>
      <Route path="/profile" element={<Profile />}/>
      <Route path="/users" element={<Users />}/>
      <Route path="/users/:action?/:id?" element={<AddEditUser />}/>
    </Routes>
  );
};

export default AppRoutes;
