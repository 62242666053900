import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextInput from './TextInput';
import './styles.scss'

const CustomAutocomplete = ({ label, options, value, disabled, onChange, name, placeholder, isMultiple, width, height, defaultVal, helperText }) => {
  return (
    <div className='custom-autocomplete' style={{width: width, height: height}}>
       <Autocomplete
        multiple={isMultiple}
        name={name || defaultVal}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disablePortal
        options={options}
        renderInput={(params) => <TextInput {...params} label={label} helperText={helperText} />}
        defaultValue={defaultVal}
        disabled={disabled}
      />
    </div>
  );
}

export default CustomAutocomplete;

