import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo/re-budget-large.svg';
import lock from '../../assets/images/login/lock.svg';
import user from '../../assets/images/login/user.svg';
import eye from '../../assets/images/login/eye.svg';
import eyeOff from '../../assets/images/login/eyeOff.svg';
import { useNavigate } from 'react-router-dom';
import typography from '../../theme/typography';
import TextInput from '../../components/TextInput';
import ThemeButton from '../../components/ThemeButton';
import './styles.scss';
import CustomTypography from '../../components/CustomTypography';
import { required, validateField } from '../../utils/Validator';
import { resetPassword } from '../../redux/slice/login';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../utils/NotificationProvider';
import PasswordStrengthMeter from './PasswordStrengthMeter';

const validationRules = {
  email: [required('Email is required')],
  oldPassword: [required('Enter your old password')],
  newPassword: [required('Enter your new password')],
};

const ResetPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [visibility, setVisibility] = useState({ oldPassword: true, newPassword: true });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const navigate = useNavigate();
  const handleChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token){
      navigate('/dashboard')
    }
  }, []);

  const handleSubmit = () => {
    if (!validateField(values, validationRules, setErrors)) return;
    dispatch(
      resetPassword({
        url: '/users/password/reset',
        method: 'POST',
        data: values,
        navigate,
        success: () => {
          navigate('/login');
        },
        fail: (err) => {
          showNotification('error', err.message);
        },
      })
    );
  };

  const getPasswordStrength = (strength) => {
    setPasswordStrength(strength)
  }

  const toggleVisibility = (field) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="logo-wrapper">
          <img src={logo} alt="logo" className='logo-image'></img>
        </div>
        <CustomTypography style={typography.headerh3} text="Reset password" />
        <div className='login-form'>
        <TextInput
          label="Email"
          placeholder="Email"
          name="email"
          type="email"
          startAdornment={<img src={user} alt="" />}
          onChange={handleChange('email')}
          helperText={errors.email}
          width="100%"
        />
        <TextInput
          label="Old password"
          placeholder="Old password"
          name="oldPassword"
          type={!visibility.oldPassword ? 'text' : 'password'}
          startAdornment={<img src={lock} alt="" />}
          onChange={handleChange('oldPassword')}
          helperText={errors.oldPassword}
          width="100%"
          endAdornment={
              <img
                src={visibility.oldPassword ? eyeOff : eye}
                alt="toggle visibility"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleVisibility('oldPassword')}
              />
            }
        />

        <TextInput
          label="New password"
          placeholder="New password"
          name="newPassword"
          type={!visibility.newPassword ? 'text' : 'password'}
          startAdornment={<img src={lock} alt="" />}
          onChange={handleChange('newPassword')}
          helperText={errors.newPassword}
          width="100%"
          endAdornment={
              <img
                src={visibility.newPassword ? eyeOff : eye}
                alt="toggle visibility"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleVisibility('newPassword')}
              />
            }
        />
        {values.newPassword && <PasswordStrengthMeter password={values.newPassword || ''} getPasswordStrength={getPasswordStrength}/>}
        <ThemeButton
          type="submit"
          variant="contained"
          style={{ textTransform: 'none' }}
          onClick={handleSubmit}
          width="100%"
          text="Reset Password"
          className="mt-20"
          isDisabled={passwordStrength < 4}
        />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
