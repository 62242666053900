import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { FormControlLabel } from "@mui/material";
import BpChecked from "../theme/checkbox/BpChecked";
import BpCheckbox from "../theme/checkbox/BpIcon";

const CustomCheckbox = (props) => {
  const { checked, handleChange, label, onClick, name, disabled, indeterminate, key,inputProps } = props;

  return (
    <div className="custom-checkbox">
    <FormControlLabel
      label={label}
      key={key}
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={handleChange}
          onClick={onClick}
          inputProps={inputProps}
          checkedIcon={<BpChecked />}
          icon={<BpCheckbox />}
          indeterminate={indeterminate}
          disabled={disabled}
        />
      }
    />
    </div>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string
};

CustomCheckbox.defaultProps = {
  checked: false,
  handleChange: () => {},
  label: "",
};

export default CustomCheckbox;
