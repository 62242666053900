import TextInput from "../../../../components/TextInput";
import SelectDropdown from "../../../../components/SelectDropdown";
import typography from "../../../../theme/typography";
import CustomTypography from "../../../../components/CustomTypography";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox";

const allTypes = [
    {label: "Workloads", value: 'WORKLOADS'},
    {label: "Free", value: 'FREE'},
]

const GroupModel = ({newGroup, workloads}) => {
    const [newGroupState, setNewGroupState] = newGroup;
    const [workloadOptions, setWorkloadOptions] = useState([]);
    const [workLoadTasks, setWorkLoadTasks] = useState();

    
    const handleWorkLoadChange = (e) => {
        const grpName = workloadOptions.find(item=> item.value === e.target.value).label
        setNewGroupState(prev=> ({...prev, groupName: grpName}))
        setNewGroupState(prev=> ({...prev, workload: e.target.value}))
    }
    
    const handleChangeGroupType = (e) => {
        setNewGroupState({groupName: '', groupType: e.target.value})
    }

    const handleChangeGroupTasks = (e) => {
        if(e.target.checked){
            setNewGroupState(prev=>({...prev, tasks: prev?.tasks ? [...prev.tasks, e.target.name] : [e.target.name]}))
        } else{
            const filteredTasks = newGroupState?.tasks?.filter((item)=>(
                item !== e.target.name
            ))
            setNewGroupState(prev=>({...prev, tasks: [...filteredTasks]}))
        }
    }

    useEffect(()=>{
        if(newGroupState.groupType === "WORKLOADS"){
            const options = workloads?.map((item)=> ({
                label: item?.name,
                value: item?._id,
            }))
            setWorkloadOptions([...options])
        }
    }, [newGroupState.groupType])

    useEffect(()=>{
        if(newGroupState?.workload){
            const selectedWorkload = workloads.find((item)=> item._id === newGroupState?.workload)
            setWorkLoadTasks(selectedWorkload?.tasks)
        }
    }, [newGroupState?.workload])
    
    return (
    <div className='user-container'>
      <div className='user-info-wrapper w-100'>
        <div className="user-info user-modal">
            <TextInput
            label="Group name"
            name="groupName"
            value={newGroupState.groupName}
            //   helperText={errors.companyName}
            onInputChange={(e) => {
                setNewGroupState(prev=> ({...prev, groupName: e.target.value}))
            }}
            sizeSmall
            disabled={(newGroupState.groupType && newGroupState.groupType === "WORKLOADS" || !newGroupState.groupType) ? true : false}
            />

            <Box className='group-type-field'>
                <CustomTypography text="Group Type" style={typography.subtitle2} />
                <SelectDropdown
                    label={"Group Type"}
                    width="20%"
                    classNameForFormControl="custom-min-width"
                    items={allTypes}
                    handleChange={(e) => handleChangeGroupType(e)}
                    name="group-type"
                    value={newGroupState.groupType}
                />
            </Box>
            {
                newGroupState.groupType === "WORKLOADS" &&
                <Box className='group-type-field'>
                    <CustomTypography text="Workloads" style={typography.subtitle2} />
                    <SelectDropdown
                        label={"Workloads"}
                        width="20%"
                        classNameForFormControl="custom-min-width"
                        items={workloadOptions}
                        handleChange={(e) => handleWorkLoadChange(e)}
                        name="workloads"
                        value={newGroupState.workload}
                    />
                </Box>
            }
        </div>
        {
            (newGroupState.groupType === "WORKLOADS" && newGroupState.workload ) &&
            <>
                <CustomTypography text="Tasks" style={typography.subtitle2} />
                <div className="task-checkboxes">
                    {
                        workLoadTasks?.map(item => (
                            <CustomCheckbox
                                key={item._id}
                                label={item.name}
                                name={item.name}
                                checked={newGroupState?.tasks?.includes(item.name)}
                                handleChange={(e) => handleChangeGroupTasks(e)}
                            />
                        ))
                    }
                </div>
            </>
        }
      </div>
    </div>
)}

export default GroupModel;