import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";
import CustomTypography from "../../components/CustomTypography";
import BpChecked from "../../theme/radio/BpChecked";
import BpIcon from "../../theme/radio/BpIcon";
import typography from "../../theme/typography";
import SelectDropdown from "../../components/SelectDropdown";
import TextInput from "../../components/TextInput";

const BpRadio = (props) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpChecked />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

const RadioButtonComponent = ({
  label,
  options,
  defaultValue,
  value,
  handleChange,
  name,
  style,
  className,
  disabled,
  checked
}) => {
  return (
    <div className="custom-checkbox">
    <FormControl>
      <FormLabel id="demo-customized-radios">
        <CustomTypography style={typography.subtitle2} text={label}/>
      </FormLabel>
      <RadioGroup
        sx={style}
        className={className}
        aria-labelledby="demo-customized-radios"
        name={name}
        value={value}
        onChange={(e)=> handleChange(name, e.target.value)}
      >
        {options?.map((item) => (
          <div key={item.value} className="flex-container">
            <FormControlLabel
              value={item.value}
              control={<BpRadio />}
              label={item.label}
              checked={
                value === item.value || (!value && defaultValue === item.value) || (checked?.val && (checked?.val === "2" || checked?.val === "3") ? parseInt(checked?.val) === item.value : false)
              }
              disabled={disabled}
            />
            {
                item.additionalFieldType === 'select' && (checked?.val && parseInt(checked?.val) === 2) ?
                <SelectDropdown
                    classNameForFormControl="custom-min-width"
                    items={item.options}
                    value={checked?.additionalFieldVal || null}
                    handleChange={(e) => handleChange(name, e.target.value, checked?.val)}
                    name="localTime"
                />
                : item.additionalFieldType === 'text' && (checked?.val && parseInt(checked?.val) === 3) ?
                <TextInput
                    placeholder="Specify time Standard"
                    name="otherTimeStandard"
                    value={checked?.additionalFieldVal || null}
                    onInputChange={(e) => handleChange(name, e.target.value, checked?.val)}
                />
                : ''
            }
          </div>
        ))}
      </RadioGroup>
    </FormControl>
    </div>
  );
};

RadioButtonComponent.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  checked: PropTypes.any,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
  showDropDown: PropTypes.bool,
};

export default RadioButtonComponent;
