import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CustomTypography from '../../../../components/CustomTypography';
import { generateUniqueId } from '../../../../utils/helper';
import TextInput from '../../../../components/TextInput';
import ThemeButton from '../../../../components/ThemeButton';
import './styles.scss';
import searchIcon from "../../../../assets/images/common/search.svg"
import GroupCard from './GroupCard';
import ActionModal from '../../../../components/ActionModal';
import GroupModel from './NewGroupModel';

const Calculations = ({modelData}) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [group, setGroup] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [addGroupModel, setAddGroupModel] = useState(false);
  const [newGroupState, setNewGroupState] = useState({
    groupName: '',
    groupType: null
  });

  const workloads = get(modelData, 'workgroup.workloads');

  const removeGroup = (data) => {
    const newGroupData = group.filter((item) => item._id !== data._id);
    setGroup([...newGroupData]);
    setSearchQuery('');
  }

  const addGroup = () => {
    const lastGroupValues = group[group.length - 1];
    const groupLetter = lastGroupValues ? String.fromCharCode(lastGroupValues?.groupLetter.charCodeAt(0) + 1) : 'A';

    let newGroupData = {
      _id: generateUniqueId(),
      type: newGroupState.groupType,
      groupCount: group.length + 1,
      name: newGroupState.groupName,
      groupLetter,
      rows: [],
    };
    if(newGroupState?.tasks?.length > 0){
      newGroupState?.tasks?.forEach((item, index)=> {
        newGroupData.rows = [
          ...newGroupData.rows,
          {
            _id: generateUniqueId(),
            name: item,
            rowId: `${groupLetter}${index + 1}`,
            outputType : "NUMBER",
            fineTuning : false,
            operation: {
                type: "OPERATOR",
                operator: "",
                isSubChild: false,
                variableOne: {},
                variableTwo: {},
            },  
          }
        ]
      })
    }
    newGroupData.rows = [
      ...newGroupData.rows,
      {
        _id: generateUniqueId(),
        name: 'Group Subtotal',
        rowId: `${groupLetter}${newGroupData.rows.length + 1}`,
        type: 'subtotal',
        fineTuning : false,
        outputType : "NUMBER",
        operation: {
            type: "OPERATOR",
            operator: "",
            isSubChild: false,
            variableOne: {},
            variableTwo: {},
        },  
      }
    ]
    setGroup(prev => ([...prev, newGroupData]));
    setSearchQuery('');
    setNewGroupState({
      groupName: '',
      groupType: null
    })
    setAddGroupModel(!addGroupModel);
  }

  const toggleAddGroupModel = () => {
    setAddGroupModel(!addGroupModel);
    setNewGroupState({
      groupName: '',
      groupType: null
    })
  }

  const actionButtons = [
    { title: "Cancel", variant: "outlined", onClick: toggleAddGroupModel },
    {
      title: "Add Group",
      variant: "contained",
      onClick: addGroup,
      isDisabled: !newGroupState.groupType || (newGroupState.groupType === "WORKLOADS" && !newGroupState?.workload) ? true : false
    },
  ];

  // const handleChangeGroupType = (e, i) => {
  //   const groupData = group;
  //   groupData[i].type = e ? e.target.value : null;
  //   setGroup([...groupData])
  // }

  // const handleNameChange = (e, i, rowindex) => {
  //     const groupData = group;
  //     if(rowindex !== undefined){
  //         groupData[i].rows[rowindex].name = e.target.value;
  //     } else {
  //         groupData[i].name = e.target.value;
  //     }
  //     setGroup([...groupData])
  // }

  useEffect(() => {
    if(searchQuery){
      let groupData = group.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredRecords([...groupData]);
    } else{
      setFilteredRecords([...group])
    }
  }, [searchQuery])

  useEffect(()=>{
    setFilteredRecords(group);
    console.log(group)
  }, [group])

  return (
    <div className='calculation-step-wrapper'>
      <div className="form-title">
        <CustomTypography
          variant="headerh3"
          text="Calculations"
        />
        <div className="flex-container">
          <TextInput
            placeholder="Search Calculation"
            sizeSmall
            variant="outlined"
            onInputChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            startAdornment={<img src={searchIcon} alt='Search Icon' />}
          />
          <ThemeButton
            text="New Group"
            variant="outlined"
            onClick={toggleAddGroupModel}
            startIcon={<AddRoundedIcon color='primary'/>}
          />
        </div>
      </div>

      {
        group?.length > 0 &&
          <GroupCard data={[group, setGroup]} filteredRecords={filteredRecords} removeGroup={removeGroup} />
      }
      <ActionModal
        open={addGroupModel}
        close={toggleAddGroupModel}
        component={<GroupModel newGroup={[newGroupState, setNewGroupState]} workloads={workloads} />}
        actionButtons={actionButtons}
        title="New Group"
        width="40%"
        className="new-user-modal"
      />
    </div>
);
};

export default Calculations;
