import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, capitalize, Grid } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomMenu from './CustomMenu';
import MenuItemList from './MenuItemList';
import AccordianTitle from './AccordianTitle';
import filterIcon from '../assets/images/common/filterIcon.svg';
import './styles.scss';
import ThemeButton from './ThemeButton';
import CustomDatePicker from './CustomDatePicker';
import CustomCheckbox from './CustomCheckbox';
import TextInput from './TextInput';
import CustomTypography from './CustomTypography';
import theme from '../theme';
import { convertCamelCaseToTitleCase, formatDate } from '../utils/helper';
import CustomChip from './CustomChip';
import moment from 'moment';
import typography from '../theme/typography';

const SearchFilter = (props) => {
  const {
    updateFilteredRows,
    fromDashboard,
    handleCloseMenu,
    openFilterModal,
    searchFilterOpt,
    resetFilterData,
    setResetFilterData,
    resetAllFilters,
    data,
    filterFE,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [openBudgetModal, setOpenBudgetModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [listSearch, setListSearch] = useState({});
  const [oldFilters, setOldFilters] = useState({});
  const [isApplyFilter, setIsApplyFilter] = useState(false);

  useEffect(() => {
    if (resetFilterData || resetAllFilters) {
      resetFilter();
    }
  }, [resetFilterData, resetAllFilters]);

  useEffect(() => {
    if (isApplyFilter) {
      applyFilter();
    }
  }, [isApplyFilter]);

  const handleClick = (event) => {
    setIsMenuOpen(event.currentTarget);
    setOldFilters(filterData);
  };

  const handleCloseFilter = () => {
    if (fromDashboard) {
      handleCloseMenu();
    }
    setIsMenuOpen(false);
  };

  const handleSearch = () => {
    if (searchQuery){
      updateFilteredRows({ ...filterData, searchQuery });
    } else {
      applyFilter()
    }
  };

  const handleCancelFilter = () => {
    setFilterData(oldFilters);
    handleCloseFilter();
  };

  const resetFilter = () => {
    setFilterData({});
    setSearchQuery('');
    setListSearch({});
    handleCloseFilter();
    if (filterFE) {
      updateFilteredRows({ data: data });
    } else {
      updateFilteredRows({});
    }
  };

  const handleCloseModal = () => {
    setOpenBudgetModal(!openBudgetModal);
  };

  const resetAccordianData = (key, value) => {
    setFilterData((prevState) => {
      const updatedData = { ...prevState };

      if (!value) {
        delete updatedData[key];
      } else {
        const updatedValues = { ...updatedData[key] };
        delete updatedValues[value];

        if (Object.keys(updatedValues).length === 0) {
          delete updatedData[key];
        } else {
          updatedData[key] = updatedValues;
        }
      }

      return updatedData;
    });
  };
  //   const budgetActionButtons = [
  //     { title: "Cancel", variant: "outlined", onClick: handleCloseModal },
  //     {
  //       title: "Apply range",
  //       variant: "contained",
  //       onClick: handleCloseModal,
  //     },
  //   ];
  const handleChange = (accordionId, columnName, value) => {
    if (typeof value === 'object') {
      setFilterData((prevState) => ({
        ...prevState,
        ...filterData,
        [accordionId]: value,
      }));
    } else {
      setFilterData((prevState) => {
        const updatedAccordion = { ...prevState[accordionId] };
        if (value === false) {
          delete updatedAccordion[columnName];
        } else {
          updatedAccordion[columnName] = value;
        }

        if (Object.keys(updatedAccordion).length === 0) {
          const updatedFilterData = { ...prevState };
          delete updatedFilterData[accordionId];
          return updatedFilterData;
        }
        return {
          ...prevState,
          [accordionId]: updatedAccordion,
        };
      });
    }
  };

  const handleListSearch = (e) => {
    const { name, value } = e.target;
    setListSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const applyFilter = () => {
    const filteredRows = data.filter((row) => {
      return Object.keys(filterData).every((columnId) => {
        const filterValues = filterData[columnId];
        const cellValue = row[columnId];
        if (typeof filterValues === 'object' && !Array.isArray(filterValues)) {
          if (columnId === 'startDate' || columnId === 'endDate') {
            const formattedFilterDate = filterValues ? formatDate(filterValues) : '';
            const formattedCellDate = cellValue ? formatDate(cellValue) : '';
            return formattedCellDate === formattedFilterDate;
          } else {
            return Object.keys(filterValues).some(
              (key) => filterValues[key] === true && cellValue === key
            );
          }
        }
        return true;
      });
    });

    if (filterFE) {
      updateFilteredRows({ data: filteredRows, filterFE: filterFE });
    } else {
      updateFilteredRows(filterData);
    }
    handleCloseFilter();
    setIsApplyFilter(false);
    setListSearch({});
    setResetFilterData(false);
  };

  const renderOptions = (item) => {
    if (item.type === 'checkbox') {
      const filteredOptions = item.options
        ?.filter((value) =>
          value.label?.toLowerCase().includes((listSearch[item.name] || '').toLowerCase())
        )
      return filteredOptions?.map((value, idx) => {
        if (!value) return null;
        return (
          <CustomCheckbox
            key={idx}
            label={value.label}
            name={value.label}
            checked={filterData[item.name]?.[value.value] || false}
            handleChange={(e) =>
              handleChange(item.name, value.value, e.target.checked)
            }
          />
        );
      });
    }
    if (item.type === 'date-picker') {
      return (
        <CustomDatePicker
          datePickerType="static"
          value={
            filterData[item.keyName] ? new Date(filterData[item.keyName]) : ''
          }
          onChange={(date) => handleChange(item.keyName, null, date)}
        />
      );
    }
    // if (item.filterType === "popup") {
    //   return (
    //     <ActionModal
    //       open={openBudgetModal}
    //       close={openBudgetModal}
    //       title="Budget Range"
    //       actionButtons={budgetActionButtons}
    //       component={<BudgetRange isModal={openBudgetModal} />}
    //     />
    //   );
    // }
  };

  const renderSearchControls = (item) => {
    if (item.type === 'checkbox') {
      return (
        <div className="flex-space-btw-wrapper">
          <TextInput
            placeholder="Search"
            sizeSmall
            variant="outlined"
            value={listSearch[item.name]}
            name={item.name}
            onInputChange={handleListSearch}
          />
          <ThemeButton
            variant="text"
            onClick={() => resetAccordianData(item.name)}
            text="Reset"
          />
        </div>
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const renderFilterChips = () => {
    return Object.entries(filterData).flatMap(([key, values]) => {
      const keyChip = (
        <CustomChip
          key={key}
          singleChip={`${capitalize(key)}: ${(key.includes('Date') && values) ? moment(new Date(values)).format('DD/MM/YYYY') : ''}`}
          handleDelete={() => {
            resetAccordianData(key);
            setIsApplyFilter(true);
          }} 
        />
      );
      const valueChips = Object.keys(values)
        .filter((value) => values[value] === true) 
        .map((value) => {
          if (key.includes('Date')) {
            return null;
          } else {
            return (
              <CustomChip
                style={{ color: '#171C26' }}
                key={`${key}-${value}`}
                singleChip={value}
                handleDelete={() => {
                  if (key.includes('Date')) {
                    resetAccordianData(key, values);
                  } else {
                    resetAccordianData(key, value);
                  }
                  setIsApplyFilter(true);
                }} 
              />
            );
          }
        });

      return [keyChip, ...valueChips];
    });
  };

  return (
    <div className="search-wrapper">
      <div className="search-filter-btn">
        {fromDashboard ? null : (
          <div className="flex-container">
            <ThemeButton
              variant="outlined"
              ariaControls={openFilterModal ? 'customized-menu' : undefined}
              ariaHaspopup="true"
              ariaExpanded={openFilterModal ? 'true' : undefined}
              disableElevation
              onClick={handleClick}
              startIcon={<img src={filterIcon} />}
              endIcon={<KeyboardArrowDownIcon />}
              text="All"
              className="mt-5"
            />

            <TextInput
              placeholder="Search"
              sizeSmall
              variant="outlined"
              onInputChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              value={searchQuery}
            />
          </div>
        )}

        <CustomMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button',
          }}
          isMenuOpen={fromDashboard ? openFilterModal : isMenuOpen}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: fromDashboard ? 'center' : 'bottom',
            horizontal: fromDashboard ? 'center' : 0,
          }}
          transformOrigin={{
            vertical: fromDashboard ? 'center' : '28%',
            horizontal: fromDashboard ? 'center' : 0,
          }}
        >
          <Grid container className="divider flex-container search-filter-head">
            <CustomTypography
              variant="headerh3"
              text="Filters"
              color={theme.palette.text.primary}
            />
            {!fromDashboard && (
              <ThemeButton
                variant="text"
                onClick={resetFilter}
                text="Reset All"
              />
            )}
          </Grid>

          <div className="accordion-list">
            {searchFilterOpt?.map((item, index) => {
              return (
                <MenuItemList
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 !important',
                  }}
                  className="menu-list-item"
                >
                  <div
                    className="w-100 h-42"
                    onClick={item.type === 'popup' ? handleCloseModal : null}
                  >
                    <Accordion>
                      <AccordianTitle
                        expandIcon={<ArrowDropDownIcon />}
                        ariaControls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <CustomTypography text={convertCamelCaseToTitleCase(capitalize(item.name))} style={typography.subtitle2} />
                      </AccordianTitle>
                      <AccordionDetails>
                        {renderSearchControls(item, index)}
                        {renderOptions(item, index)}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </MenuItemList>
              );
            })}
          </div>

          <div className="flex-space-btw-wrapper w-100 pd-16 bg-white menu-btn-wrapper">
            <ThemeButton
              variant="outlined"
              onClick={handleCancelFilter}
              text="Cancel"
            />
            <ThemeButton
              variant="contained"
              onClick={applyFilter}
              text="Apply Filters"
            />
          </div>
        </CustomMenu>
      </div>
      {filterFE && <div className="search-chip-display">
        <div className='d-flex'>
          <CustomTypography text={`Filters applied: ${Object.keys(filterData).length}`} style={typography.subtitle2}/>
          {Object.keys(filterData).length > 0 && 
          <ThemeButton variant="text" onClick={resetFilter} text="Clear all" />
          }
        </div>
        {renderFilterChips()}
      </div>}
    </div>
  );
};

SearchFilter.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  updateFilteredRows: PropTypes.func,
  fromDashboard: PropTypes.bool,
  openFilterModal: PropTypes.bool,
  handleCloseMenu: PropTypes.func,
  dashboardData: PropTypes.array,
};

SearchFilter.defaultProps = {
  columns: [],
  data: [],
  dashboardData: [],
  updateFilteredRows: () => {},
  fromDashboard: false,
};

export default SearchFilter;
