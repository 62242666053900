 import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import CompanyHierarchy from './CompanyHierarchy';
import TimeStandards from './TimeStandards';
import Roles from './Roles';
import Drivers from './Drivers';
import Calendar from './Calendar';
import Calculations from './Calculations';
import GlobalSettings from './GlobalSettings';
import ThemeButton from '../../../components/ThemeButton';
import VerticalStepper from '../../../components/VerticalStepper';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  budgetTypeOpt,
  currencyOpt,
  weekDays,
  weekModelTabs,
} from './GlobalSettings/constant';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addModel, fetchModel, getStepsInfo } from '../../../redux/slice/model';
import CompanyCharacteristics from './CompanyCharacteristics';
import { get } from 'lodash';
import { deepCompareArrays, deepCompareObjects, formatDate, formatNum, validateFormData } from '../../../utils/helper';
import { useNotification } from '../../../utils/NotificationProvider';
import { ValueTypes } from './GlobalSettings/Group/constant';
import { initialSteps } from '../../../utils/constants';
import Workgroup from './Workgroup';
import CustomDialogBox from '../../../components/CustomDialogBox';
import Loading from '../../../utils/loading';

const AddEditModel = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [validationRules, setValidationRules] = useState({})
  const [errors, setErrors] = useState({}); 
  const [modelId, setModelId] = useState('');
  const [completedSteps, setCompletedSteps] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const params = useParams();
  const editMode = params.action === 'edit-model';
  const [modelSteps, setModelSteps] = useState([])
  const [expandMenu, setExpandMenu] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isCancelAlert, setIsCancelAlert] = useState(false);
  const [openStep, setOpenStep] = useState(true);
  const [modelData, setModelData] = useState();

  useEffect(() => {
    setModelId(get(params, 'id', ''));
  }, [params]);

  useEffect(() => {
    setModelSteps([...initialSteps]);
  }, []);

  useEffect(() => {
    if (editMode) {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }
}, [editMode]);

  const steps = [
    {
      component: (
        <GlobalSettings
          formData={formData}
          setFormData={setFormData}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          setErrors={setErrors}
          errors={errors}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
        />
      ),
      heading: 'Global Settings',
      key: 'globalSettings',
    },
    {
      component: (
        <CompanyHierarchy
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),
      heading: 'Company hierarchy',
      key: 'companyHierarchy',
    },
    {
      component: (
        <CompanyCharacteristics
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
        />
      ),
      heading: 'Characteristics',
      key: 'companyCharacteristics',
    },
    {
      component: (
        <Roles
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
        />
      ),

      heading: 'Roles',
      key: 'role',
    },
    {
      component: (
        <TimeStandards
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: "SMVs",
      key: 'timeStandards',
    },
    {
      component: (
        <Workgroup
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
        />
      ),

      heading: 'Workgroup Management',
      key: 'workgroupManagement',
    },
    {
      component: (
        <Drivers
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Weekly Drivers',
      key: 'drivers',
    },
    {
      component: (
        <Calendar
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Calendar data',
      key: 'calendar',
    },
    {
      component: (
        <Calculations
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          completedSteps={completedSteps}
          loading={loading}
          validationRules={validationRules}
          setValidationRules={setValidationRules}
          modelData={modelData}
        />
      ),

      heading: 'Calculation',
      key: 'calculation',
    },
  ];

  const fetchStepsInfo = () => {
    dispatch(getStepsInfo({
      url: `/models/steps/${modelId}`,
      method: 'GET',
      success: (res) => {
        setCompletedSteps(res.data);
      },
      fail: (err) => {
        console.log("err", err)
      }
    }));
  };

  useEffect(() => {
    if (modelId){
      fetchStepsInfo();
    }
  }, [modelId]);

 const getModelData = () => {
  dispatch(
    fetchModel({
      url: `/models/${modelId}`,
      method: 'GET',
      navigate,
      success: (res) => {
        const data = get(res, 'data[0].model', {});
        setModelData(data);
        setLoading(false);
      },
      fail: (err) => {
        console.log('err of single model', err);
        setLoading(false);
      },
    })
  );
}

 useEffect(() => {
  if (get(completedSteps, 'globalSettings')){
    getModelData()
  }
 }, [modelId, completedSteps])

  const getActiveStep = () => {
    const nextStepIndex = modelSteps.findIndex(step => !completedSteps[step.key]);
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === nextStepIndex) {
        return { ...step, status: 'inProgress' };
      }
      if (index < nextStepIndex) {
        return { ...step, status: 'completed' };
      }
      if (index > nextStepIndex) {
        return { ...step, status: 'pending' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep(nextStepIndex);
    setOpenStep(false);
};

useEffect(() => {
  if (completedSteps && openStep && editMode){
    getActiveStep();
  }
}, [completedSteps])

  /* eslint-disable no-unused-vars */
  const getPayload = (payload, stepName) => {
    let formattedPayload = {};
    
    const isEqual = (row, prevRow) => {
      return Object.keys(row).every((key) => {
        const rowValue = row[key];
        const prevRowValue = prevRow[key];
    
        if (Array.isArray(rowValue) && Array.isArray(prevRowValue)) {
          return deepCompareArrays(rowValue, prevRowValue, 0); 
        }
        if (
          typeof rowValue === "object" &&
          rowValue !== null &&
          typeof prevRowValue === "object" &&
          prevRowValue !== null
        ) {
          return deepCompareObjects(rowValue, prevRowValue);
        }
        return row[key] === prevRow[key];
      });
    };

    // payload for global settings (step 1)
    if (stepName === 'globalSettings') {
      const {
        fyName,
        currency,
        budgetType,
        budgetStartDate,
        budgetEndDate,
        modelWeeks,
        parameters,
        weekStartDay,
      } = payload;

      const findLabel = (array, value, key) =>
        array.find((item) => item.value === value)?.[key];
      const transformedData = parameters.map((group) => ({
        name: group.groupName,
        _id: group.id,
        parameters: group.parameters.map((param) => ({
          name: param.parameterName,
          note: param.notes || '',
          type: ValueTypes.find((item) => item.value === param.type)?.type,
          values: param.value,
          _id: param.id
        })),
      }));
    const modelData = {
      name: fyName,
      budgetType: findLabel(budgetTypeOpt, budgetType, 'label')?.toLowerCase(),
      currency: findLabel(currencyOpt, currency, 'label'),
      numberOfSplit: findLabel(weekModelTabs, modelWeeks, 'title'),
      reportingPeriod: 'monthly',
      weekStartDay: findLabel(weekDays, weekStartDay, 'label')?.toLowerCase(),
      budgetStartDate: budgetStartDate
        ? moment(budgetStartDate).format('YYYY-MM-DD')
        : null,
      budgetEndDate: budgetEndDate
        ? moment(budgetEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      parameterGroups: transformedData,
    };
    const prevData = {
      name: payload.prevData.name,
      budgetType: payload.prevData.budgetType,
      currency: payload.prevData.currency,
      numberOfSplit: payload.prevData.numberOfSplit,
      reportingPeriod: payload.prevData.reportingPeriod,
      weekStartDay: payload.prevData.weekStartDay,
      budgetStartDate: payload.prevData.budgetStartDate
        ? moment(new Date(payload.prevData.budgetStartDate)).format('YYYY-MM-DD')
        : null,
      budgetEndDate: payload.prevData.budgetEndDate
        ? moment(new Date(payload.prevData.budgetEndDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      parameterGroups: payload.prevData.parameterGroups,
    }
     if (!isEqual(modelData, prevData)){
        formattedPayload.model = modelData
      } else {
        formattedPayload = null;
      }

    }

    // payload for company hierarchy (step 2)
    if (stepName === 'companyHierarchy') {
      const hierarchyData = {
        updateStores: [],
        addedStores: [],
        deletedStores: [],
        levels: [],
        structureColumns: [],
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          no: Number(item.no),
          startDate: item.startDate ? formatDate(item.startDate) : '',
          endDate: item.endDate ? formatDate(item.endDate) : '',
        }));
        return rowData;
      };

      formattedData(get(payload, 'stores', [])).forEach((row) => {
        const matchingPrev = formattedData(get(payload, 'prevData.rows', [])).find((prev) => prev.id === row.id);
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            hierarchyData.updateStores.push(row);
          }
        } else {
          hierarchyData.addedStores.push(row);
        }
      });

      formattedData(get(payload, 'prevData.rows', [])).forEach((prev) => {
        const matchingRow = formattedData(get(payload, 'stores', [])).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          hierarchyData.deletedStores.push(prev.id);
        }
      });

      const transformedData = (data, isUpdate) => {
        const rows = data.map(
          ({
            id,
            no,
            name,
            startDate,
            endDate,
            characteristicData,
            ...rest
          }) => {
            const structureData = {};
            for (const [key, value] of Object.entries(rest)) {
              structureData[key] = value;
            }
            let requestedObj = {};
            requestedObj = {
              no: no ? Number(no) : '',
              name,
              startDate,
              endDate,
              structureData,
              characteristicData,
            };
            if (isUpdate) {
              requestedObj._id = id;
            }
            return requestedObj;
          }
        );
        return rows;
      };

      const levelData = payload.level.map((lv) => {
       return lv.value.map(item => item.value)
      })

      if (
        hierarchyData.addedStores.length === 0 &&
        hierarchyData.updateStores.length === 0 &&
        hierarchyData.deletedStores.length === 0 &&
        deepCompareArrays(
          get(payload, 'structureColumns', []),
          get(payload, 'prevData.columns', []),
          1
        ) &&
        deepCompareArrays(
          get(payload, 'level', []),
          get(payload, 'prevData.levels', []),
          0
        )
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          hierarchy: {
            level: levelData,
            addStores: transformedData(hierarchyData.addedStores, false),
            updateStores: transformedData(hierarchyData.updateStores, true),
            deleteStores: hierarchyData.deletedStores,
            structureColumns: payload.structureColumns,
          },
        };
      }
    }

    // payload for company characteristics (step 3)
    if (stepName === 'companyCharacteristics') {
      const characteristicsData = {
        updateStores: [],
      };
      get(payload, 'stores', []).forEach((row) => {
        const matchingPrev = get(payload, 'prevData.rows', []).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            characteristicsData.updateStores.push(row);
          }
        }
      });
      const transformedData = characteristicsData.updateStores.map(
        ({ no, name, id, ...rest }) => {
          const characteristicData = {};
          for (const [key, value] of Object.entries(rest)) {
            characteristicData[key] = value;
          }
          return {
            _id: id,
            characteristicData,
          };
        }
      );
      if (
        characteristicsData.updateStores.length === 0 &&
        deepCompareArrays(
          get(payload, 'characteristicColumns', []),
          get(payload, 'prevData.columns', []),
          0
        )
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          hierarchy_characteristicData: {
            characteristicColumns: payload.characteristicColumns,
            updateStores: transformedData,
          },
        };
      }
    }

     // payload for tasks (step 4)
    if (stepName === 'timeStandards') {
      const tasksData = {
        addTasks: [],
        updateTasks: [],
        deleteTasks: [],
      };

      const formattedData = (arr) => {
        const rowData = arr.map((item) => ({
          ...item,
          basicMinutes: Number(item.basicMinutes),
          relaxationAllowance: Number(item.relaxationAllowance),
          contingencyAllowance: Number(item.contingencyAllowance),
          standardMinuteValue: Number(item.standardMinuteValue),
          startDate: item.startDate ? formatDate(item.startDate) : '',
          endDate: item.endDate ? formatDate(item.endDate) : '',
        }));
        return rowData;
      };

      formattedData(get(payload, 'tasks', [])).forEach((row) => {
        const matchingPrev = formattedData(get(payload, 'prevData', [])).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            tasksData.updateTasks.push(row);
          }
        } else {
          tasksData.addTasks.push(row);
        }
      });

      formattedData(get(payload, 'prevData', [])).forEach((prev) => {
        const matchingRow = formattedData(get(payload, 'tasks', [])).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          tasksData.deleteTasks.push(prev.id);
        }
      });

      const transformedData = (data, isUpdate) => {
        const rows = data.map(
          (item) => {
            const {
              id,
              startDate,
              endDate,
              basicMinutes,
              relaxationAllowance,
              contingencyAllowance,
              standardMinuteValue,
              name,
              unitOfMeasure,
            } = item;
            let requestedObj = {};
            requestedObj = {
              startDate: startDate ? formatDate(startDate) : '',
              endDate: endDate ? formatDate(endDate) : '',
              basicMinutes: formatNum(basicMinutes),
              relaxationAllowance: Number(relaxationAllowance),
              contingencyAllowance: Number(contingencyAllowance),
              standardMinuteValue: formatNum(standardMinuteValue),
              name,
              unitOfMeasure
            };
            if (isUpdate) {
              requestedObj._id = id;
            }
            return requestedObj;
          }
        );
        return rows;
      };
      if (
        tasksData.addTasks.length === 0 &&
        tasksData.updateTasks.length === 0 &&
        tasksData.deleteTasks.length === 0
      ) {
        formattedPayload = null;
      } else {
        formattedPayload = {
          task: {
            addTasks: transformedData(tasksData.addTasks, false),
            updateTasks: transformedData(tasksData.updateTasks, true),
            deleteTasks: tasksData.deleteTasks,
          },
        };
      }
    }

     // payload for Workgroup (step 5)
     if (stepName === "workgroupManagement") {
      const workgroupData = {
        addWorkloads: [],
        updateWorkloads: [],
        deleteWorkloads: [],
      };

      get(payload, 'workgroups', []).forEach((row) => {
        const matchingPrev = get(payload, 'prevData', []).find(
          (prev) => prev.id === row.id
        );
        if (matchingPrev) {
          if (!isEqual(row, matchingPrev)) {
            workgroupData.updateWorkloads.push(row);
          }
        } else {
          workgroupData.addWorkloads.push(row);
        }
      });

      get(payload, 'prevData', []).forEach((prev) => {
        const matchingRow = get(payload, 'workgroups', []).find(
          (row) => row.id === prev.id
        );

        if (!matchingRow) {
          workgroupData.deleteWorkloads.push(prev.id);
        }
      });
      
      const transformedData = (data, isUpdate) => {
        const rows = data.map(
          (item) => {
            const {
              workload, tasks, id
            } = item
            let requestedObj = {};
            requestedObj = {
              name: workload,
              tasks: tasks.map((item) => item.value) || []
            };
            if (isUpdate) {
              requestedObj._id = id;
            }
            return requestedObj;
          }
        );
        return rows;
      };
      if (
        workgroupData.addWorkloads.length === 0 &&
        workgroupData.updateWorkloads.length === 0 &&
        workgroupData.deleteWorkloads.length === 0
      ){
        formattedPayload = null;
      } else {
      formattedPayload = {
        workgroup: {
          addWorkloads: transformedData(workgroupData.addWorkloads, false),
          updateWorkloads: transformedData(workgroupData.updateWorkloads, true),
          deleteWorkloads: workgroupData.deleteWorkloads,
        },
      };
    }
  }

  // payload for Role (step 6)
  if (stepName === "role") {
    const formattedStores = (arr) => {
      const rowData = arr.map((item) => ({
        ...item,
        postPayRise: Number(item.payPostPayRise),
        prePayRise: Number(item.payPrePayRise),
        payRise: Number(item.payRise),
        payRiseWeek: Number(item.payRiseWeek),
        FTEHours: Number(item.hours),
        rule: Number(item.rule),
      }))
      return rowData;
    }
    const formattedData = (arr) => {
      const rowData = arr.map((item) => ({
        ...item,
        stores: formattedStores(item.stores)
      }))
      return rowData
    }
    const prevData = formattedData(get(payload, 'prevData', []));
    const groups = formattedData(get(payload, 'groups', []));
    
    const  getModifiedRoles = (prevRoles, updatedRoles) => {
      return updatedRoles
        .filter(role => role.type)
        .map(role => {
          if (role.type === "UPDATE_ROLE") {
            const originalRole = prevRoles.find(r => r.id === role.id);
            if (!originalRole) return role;
            const modifiedStores = role.stores.filter(store => store.type);
            const storesChanged = modifiedStores.length > 0 || role.stores.length !== originalRole.stores.length;
            return {
              ...role,
              stores: storesChanged ? modifiedStores : [],
            };
          }
          return role;
        });
    }

    const transformmedData = (data) => {
      const rows = data.map((item) => {
        const {maximum, minimum, roleName, stores, type, id} = item;
        let requestedObj = {};
        requestedObj = {
          name: roleName,
          type,
          stores: stores.map((st) => {
            const { storeId, type, payPostPayRise, payPrePayRise, payRise, payRiseWeek, FTEHours, rule, id} = st;
            let requestedStore = {}
            requestedStore = {
              storeId,
              type,
              postPayRise: Number(payPostPayRise),
              prePayRise: Number(payPrePayRise),
              payRise: Number(payRise),
              payRiseWeek: Number(payRiseWeek),
              FTEHours: Number(FTEHours),
              rule: Number(rule),
            }
            return requestedStore
          })
        }
        if (type !== 'NEW_ROLE'){
          requestedObj._id = id
        }
        return requestedObj;
      });
      return rows;
    }
    const modifiedData = getModifiedRoles(prevData, groups)
    if (modifiedData.length === 0){
      formattedPayload = null;
    } else {
      formattedPayload = {
        role: {
          groups: transformmedData(modifiedData)
        }
      }
    }
  }  

  // payload for Drivers (step 7)
  if (stepName === "drivers"){
    const formattedStores = (stores) => {
      return stores.map(store => {
        const updatedStore = { ...store };
    
        Object.keys(updatedStore).forEach(key => {
          if (key.startsWith('wd')) {
            updatedStore[key] = parseFloat(updatedStore[key]); 
          }
        });
    
        return updatedStore;
      });
    };

    const formattedData = (arr) => {
      const rowData = arr.map((item) => ({
        ...item,
        stores: formattedStores(item.stores)
      }))
      return rowData
    }

    const prevData = formattedData(get(payload, 'prevData', []));
    const groups = formattedData(get(payload, 'groups', []));

    const getModifiedDrivers = (prevDrivers, updatedDrivers) => {
      return updatedDrivers.filter(driver => driver.type).map(driver => {
       if (driver.type === "UPDATE_DRIVER") {
        const originalDriver = prevDrivers.find(r => r.id === driver.id);
        if (!originalDriver) return driver;
        const modifiedStores = driver.stores.filter(store => store.type);
        const storesChanged = modifiedStores.length > 0 || driver.stores.length !== originalDriver.stores.length;
        return {
          ...driver,
          stores: storesChanged ? modifiedStores : [],
        };
      }
      return driver;
    });
}

    const transformData = (drivers) => {
      const rows = drivers.map(driver => {
        let requestedObj = {};
       requestedObj = { 
        name: driver.name,
        actualData: driver.actualData,
        type: driver.type,
        stores: driver.stores.map(store => {
          const values = Object.keys(store)
            .filter(key => key.startsWith('wd-')) 
            .sort((a, b) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])) 
            .map(key => parseFloat(store[key])); 
            let requestedStore = {};
            requestedStore = {
              storeId: store.storeId,
              type: store.type,
              values: values
            }
            return requestedStore;
        })}
        if (driver.type !== 'NEW_DRIVER'){
          requestedObj._id = driver.id
        }
        return requestedObj;
      });
      return rows;
    };

    const modifiedData = getModifiedDrivers(prevData, groups);
    if (modifiedData.length === 0){
      formattedPayload = null;
    } else {
      formattedPayload = {
        driver: {
          groups: transformData(modifiedData)
        }
      }
    }
  }

  if (stepName === 'calendar'){
    const calendarData = {
      addCalendars: [],
      updateCalendars: [],
      deleteCalendars: [],
    }

    const formattedData = (arr) => {
      const rowData = arr.map((item) => {
       return {
        ...item,
        inputType: '',
        cellType: ''
      }
    });
      return rowData;
    };

  formattedData(get(payload, 'data', [])).forEach((row) => {
    const matchingPrev = formattedData(get(payload, 'prevData', [])).find(
      (prev) => prev.id === row.id
    );
    if (matchingPrev) {
      if (!isEqual(row, matchingPrev)) {
        calendarData.updateCalendars.push(row);
      }
    } else {
      calendarData.addCalendars.push(row);
    }
  });

  formattedData(get(payload, 'prevData', [])).forEach((prev) => {
    const matchingRow = formattedData(get(payload, 'data', [])).find(
      (row) => row.id === prev.id
    );
 
    if (!matchingRow) {
      calendarData.deleteCalendars.push(prev.id);
    }
  });

  const transformmedData = (data, isUpdate) => {
    const rows = data.map((item) => {
      const {name, type, id, ...rest} = item;
      const values = Object.keys(rest)
      .filter(key => key.startsWith('wd-')) 
      .sort((a, b) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])) 
      .map(key => type === 'boolean' ? rest[key].value : rest[key].toString() ); 
      const requestedObj = {
        name,
        type,
        values
      }
      if (isUpdate){
        requestedObj._id = id
      }
      return requestedObj;
    });
    return rows;
  }

  if (
    calendarData.addCalendars.length === 0 &&
    calendarData.updateCalendars.length === 0 &&
    calendarData.deleteCalendars.length === 0
  ) {
    formattedPayload = null;
  } else {
    formattedPayload = {
      calendar: {
        addCalendars: transformmedData(calendarData.addCalendars, false),
        updateCalendars: transformmedData(calendarData.updateCalendars, true),
        deleteCalendars: calendarData.deleteCalendars,
      },
    };
  }
}
    return formattedPayload;
  };

  const onNext = () => {
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === activeStep) {
        return { ...step, status: 'completed' };
      }
      if (index === activeStep + 1) {
        return { ...step, status: 'inProgress' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    fetchStepsInfo()
  };

  const handleAddModelStep = (payloadData, stepName, saveDraft) => {
    const validateErrors = validateFormData(payloadData, validationRules[stepName]);
    const formattedPayload = getPayload(payloadData, stepName);
    if (validateErrors && validateErrors.length > 0){
      showNotification('error', validateErrors[0]);
      return;
    }
    if (formattedPayload) {
      dispatch(
        addModel({
          url: '/models/steps',
          method: 'POST',
          navigate,
          data: {
            modelId: modelId || '',
            data: formattedPayload,
          },
          success: (res) => {
            if (stepName === 'globalSettings') {
              setModelId(res.data.model._id);
            }
            if (saveDraft) {
              handleClose();
              if(!params.id){
                showNotification('success', res?.message);
              } else{
                showNotification('success', "Model updated successfully");
              }
            } else {
              onNext();
            }
          },
          fail: (err) => {
            console.log('err', err);
            const errorMessage = err.message || 'Something went wrong.';
            showNotification('error', errorMessage);
          },
        })
      );
    } else {
      if (saveDraft) {
        handleClose();
      } else {
        onNext();
      };
    };
  };

  const handleNext = (saveDraft) => {
    const stepName = steps[activeStep]?.key;
    const valuesToValidate = formData[stepName] || {};
    handleAddModelStep(valuesToValidate, stepName, saveDraft);
  };

  const handleBack = () => {
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === activeStep) {
        return { ...step, status: 'pending' };
      }
      if (index === activeStep - 1) {
        return { ...step, status: 'inProgress' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    navigate('/models');
    setActiveStep(0);
  };

  const handleCloseCancelAlert = () => {
    setIsCancelAlert(false);
  };

  const onBack = () => {
    navigate(-1);
    setIsCancelAlert(false);
  };

  const cancelActions = [
    { title: 'Continue', variant: 'outlined', onClick: handleCloseCancelAlert },
    {
      title: 'Cancel changes',
      variant: 'contained',
      onClick: onBack,
      color: 'danger',
    },
  ];

  return (
    <>
      {loading ? <Loading /> : <Grid container className="model-container" spacing={3} pr={0}>
        <Grid item className="model-wrapper">
          <VerticalStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={modelSteps}
            expandMenu={expandMenu}
            setExpandMenu={setExpandMenu}
          />
        </Grid>
        <Grid item className="model-step-container"
        //  style={{maxWidth: `${expandMenu ? 'calc(100% - 316px)' : 'calc(100% - 112px)'}`}}
         >
          <div className="model-step-wrapper">
            {steps[activeStep]?.component}
          </div>

          <div>
            <div className="flex-container model-step-footer">
            <div className="flex-container" style={{ gap: '10px' }}>
              <ThemeButton
                text="Save Draft"
                onClick={() => handleNext(true)}
                variant="outlined"
              />
              <ThemeButton
                text="Leave"
                onClick={() => setIsCancelAlert(true)}
                variant="contained"
                color="danger"
              />
              </div>
              <div className="flex-container" style={{ gap: '10px' }}>
                {activeStep > 0 && (
                  <ThemeButton
                    text="Previous"
                    onClick={handleBack}
                    variant="text"
                  />
                )}

                {activeStep < 8 && (
                  <ThemeButton
                    text="Next step"
                    onClick={() => handleNext(false)}
                    variant="contained"
                    disabled={loading}
                  />
                )}
                {activeStep === 8 && (
                  <ThemeButton
                    text={editMode ? "Save changes" : "New model"}
                    onClick={handleClose}
                    variant="contained"
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>}
        <CustomDialogBox
          open={isCancelAlert}
          onClose={handleCloseCancelAlert}
          title="Cancel Changes?"
          description="Are you sure you want to leave? Please note, any progress made so far will be lost."
          actions={cancelActions}
        />
    </>
  );
};

AddEditModel.propTypes = {
  component: PropTypes.element.isRequired,
};
export default AddEditModel;
